//Redux
//Router
import { routerMiddleware } from "connected-react-router";
import { createBrowserHistory } from "history";
import { applyMiddleware, compose, createStore } from "redux";
import createRootReducer from "../reducers";

//Middleware
//Logger
import logger from "redux-logger";

//Sagas
import createSagaMiddleware from "redux-saga";
import rootSaga from "./../sagas/index";

//Thunk
import thunk from "redux-thunk";

//Create Middleware
export const history = createBrowserHistory();
const sagaMiddleware = createSagaMiddleware();

export default function configureStore(preloadedState) {
  let store;
  store =
    process.env.NODE_ENV === "production"
      ? createStore(
          createRootReducer(history), // root reducer with router state
          preloadedState,
          compose(
            applyMiddleware(
              routerMiddleware(history), // for dispatching history actions
              sagaMiddleware,
              thunk
            )
          )
        )
      : createStore(
          createRootReducer(history), // root reducer with router state
          preloadedState,
          compose(
            applyMiddleware(
              routerMiddleware(history), // for dispatching history actions
              logger,
              sagaMiddleware
            )
          )
        );

  //Run sagas
  sagaMiddleware.run(rootSaga);

  return store;
}
