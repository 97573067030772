import React, { Component } from "react";

import { connect } from "react-redux";
import { push } from "connected-react-router";
import * as ACTIONS from "../actions";

// MUI
import CircularProgress from "@material-ui/core/CircularProgress";
import Container from "@material-ui/core/Container";
import CssBaseline from "@material-ui/core/CssBaseline";
import Typography from "@material-ui/core/Typography";

// Cal Support
import MomentUtils from "@date-io/moment";
import { DateTimePicker } from "@material-ui/pickers";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";

//Components
import HistoryTable from "../components/History/HistoryTable";

import { isEmpty } from "lodash";
import { LinearProgress, Grid } from "@material-ui/core";

class History extends Component {
  constructor(props) {
    super();
    this.state = {
      start: null,
      end: null
    };
  }
  componentDidMount() {
    this.props.getData(this.props.api, "buildings", null, "buildings");
  }

  render() {
    const { api, buildings, fetching, match, role } = this.props;
    const site = match.params.site;
    const { start, end } = this.state;

    const handleDateChange = variant => e => {
      this.setState({ [variant]: e });
    };

    if (isEmpty(buildings)) {
      return <LinearProgress />;
    }

    const siteBuildings = buildings.filter(
      building => building.siteId === site
    );

    if (fetching) {
      return (
        <Container
          maxWidth="md"
          style={{
            position: "absolute",
            display: "flex",
            top: "50%",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center"
          }}
        >
          <CircularProgress variant="indeterminate" color="primary" />
        </Container>
      );
    }

    return (
      <>
        <CssBaseline />
        <Typography
          variant="h5"
          style={{
            padding: "2rem 0",
            fontVariant: "all-petite-caps",
            display: "flex",
            justifyContent: "center",
            textAlign: "center",
            width: "100%"
          }}
        >
          Building History
        </Typography>
        <Grid
          container
          style={{ padding: "0 0 2rem 0", borderBottom: "3px solid #ccc" }}
        >
          <Grid
            item
            xs={6}
            style={{ display: "flex", justifyContent: "center" }}
          >
            <MuiPickersUtilsProvider utils={MomentUtils}>
              <DateTimePicker
                disableFuture
                clearable
                label="Start Date"
                inputVariant="standard"
                variant="dialog"
                value={start}
                onChange={handleDateChange("start")}
              />
            </MuiPickersUtilsProvider>
          </Grid>
          <Grid
            item
            xs={6}
            style={{ display: "flex", justifyContent: "center" }}
          >
            <MuiPickersUtilsProvider utils={MomentUtils}>
              <DateTimePicker
                disableFuture
                clearable
                label="End Date"
                inputVariant="standard"
                variant="dialog"
                value={end}
                onChange={handleDateChange("end")}
              />
            </MuiPickersUtilsProvider>
          </Grid>
        </Grid>
        <Container maxWidth="md" style={{ padding: "1rem 0 2rem 0" }}>
          {siteBuildings.map(building => {
            return (
              <HistoryTable
                api={api}
                key={building.id}
                data={building}
                title={building.name}
                start={start}
                end={end}
                role={role}
              />
            );
          })}
        </Container>
      </>
    );
  }
}

export default connect(
  state => ({
    api: state.user.api,
    buildings: state.data.buildings,
    fetching: state.data.fetching,
    role: state.user.role
  }),
  dispatch => ({
    reroute: route => dispatch(push(route)),
    getData: (api, filter, params, storeAs) =>
      dispatch({
        type: ACTIONS.DATA_SAGA,
        payload: { api, filter, params, storeAs }
      })
  })
)(History);
