import { all } from "redux-saga/effects";

//Watchers
import authSaga from "./auth";
import dataSaga from "./data";
import postSaga from "./post";
import userSaga from "./user";

export default function* rootSaga() {
  yield all([authSaga(), userSaga(), dataSaga(), postSaga()]);
}
