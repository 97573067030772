import React, { Component } from "react";
import { connect } from "react-redux";

//Auth
import { doLogOut } from "./../firebase/auth";
import * as ACTIONS from "../actions";

//Router
import * as ROUTE from "../routes";
import { push, goBack } from "react-router-redux";

//MUI
import AppBar from "@material-ui/core/AppBar";
import IconButton from "@material-ui/core/IconButton";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import Toolbar from "@material-ui/core/Toolbar";

import { withStyles } from "@material-ui/core/styles";

//Icons
import BrandIcon from "../static/Icons/district.png";
// import BrandIcon from "../static/Icons/lynx.png";

import PersonIcon from "@material-ui/icons/Person";
import KeyboardArrowLeftIcon from "@material-ui/icons/KeyboardArrowLeft";

class Navigation extends Component {
  state = {
    anchorEl: null,
    itemSelected: null
  };

  render() {
    const { classes, api, authUser, location, reroute } = this.props;
    const { anchorEl } = this.state;

    const open = Boolean(anchorEl);

    const logOutHandler = () => {
      this.handleUserMenuClose();
      reroute(ROUTE.LANDING);
      this.props.resetApp();
      doLogOut();
    };

    const handleBack = () => {
      this.props.backButton();
    };

    const getIcon = () => {
      switch (api) {
        case "kvznwaTLRfxYzTcLh7DU6CBzO8mhzat585AGmBv9": {
          return BrandIcon;
        }

        case "xUHB6sXQHm3Wh0RLWPzHH1Dcqd0bKrYxalkzDnYt": {
          return BrandIcon;
        }

        default: {
          return BrandIcon;
        }
      }
    };

    return (
      <div className={classes.root}>
        {/* AppBar Logic */}
        <AppBar position="fixed" className={classes.appBar}>
          <Toolbar style={{ justifyContent: "space-between" }}>
            {/* Brand */}
            {location.pathname.startsWith("/point") ? (
              <IconButton
                aria-owns={open ? "menu-appbar" : null}
                aria-haspopup="true"
                onClick={() => handleBack()}
                color="inherit"
                style={{ border: "1px solid #ccc" }}
              >
                <KeyboardArrowLeftIcon />
              </IconButton>
            ) : (
              <img
                onClick={() => reroute(ROUTE.DASHBOARD)}
                src={getIcon()}
                alt={"logo"}
                height={30}
                style={{ margin: ".5rem", cursor: "pointer" }}
              />
            )}

            {authUser && location.pathname !== "/" ? (
              <IconButton
                aria-owns={open ? "menu-appbar" : null}
                aria-haspopup="true"
                onClick={this.handleUserMenuOpen}
                color="inherit"
              >
                <PersonIcon />
              </IconButton>
            ) : null}

            <Menu
              id="menu-appbar"
              anchorEl={anchorEl}
              anchorOrigin={{
                vertical: "top",
                horizontal: "right"
              }}
              transformOrigin={{
                vertical: "top",
                horizontal: "right"
              }}
              open={open}
              onClose={this.handleUserMenuClose}
            >
              {/* ./Navigation/UserMenu */}
              <div style={{ outline: "none", fontVariant: "all-petite-caps" }}>
                <MenuItem onClick={() => this.handleRoute(ROUTE.PROFILE)}>
                  profile
                </MenuItem>
                <MenuItem onClick={() => logOutHandler()}>logout</MenuItem>
              </div>
            </Menu>
          </Toolbar>
        </AppBar>
        <div className={classes.toolbar} />
      </div>
    );
  }

  // SignInDialog Handlers
  handleRoute = route => {
    this.props.reroute(route);
    this.setState({ anchorEl: null });
  };

  //UserMenu Handlers
  handleUserMenuOpen = event => {
    this.setState({ anchorEl: event.currentTarget });
  };

  handleUserMenuClose = () => {
    this.setState({ anchorEl: null });
  };

  handleMenuItemClick = item => {
    const { itemSelected } = this.state;
    this.setState({ itemSelected: itemSelected === item ? null : item });
  };
}

//Styles
const styles = theme => ({
  root: {
    flexGrow: 1,
    [theme.breakpoints.down("xs")]: {
      marginBottom: 56
    },
    [theme.breakpoints.up("sm")]: {
      marginBottom: 64
    }
  },
  appBar: {
    boxShadow: "none",
    zIndex: theme.zIndex.drawer + 1,
    backgroundColor: theme.palette.background.paper,
    color: "#333",
    borderBottom: `1px solid ${theme.palette.secondary.main}`
  },
  typography: {
    flex: 1,
    textAlign: "center",
    // paddingLeft: "1em",
    cursor: "pointer"
  },
  toolbar: {}
});

//Connect
const mapStateToProps = state => ({
  authUser: state.session.authUser,
  api: state.user.api,
  location: state.router.location
});

const mapDispatchToProps = dispatch => ({
  reroute: target => dispatch(push(target)),
  backButton: () => dispatch(goBack()),
  resetApp: () =>
    dispatch({
      type: ACTIONS.RESET_APP
    })
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(Navigation));
