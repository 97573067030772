import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";

import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
//Hooks
import { makeStyles } from "@material-ui/core/styles";
//Icons
import ViewCarouselIcon from "@material-ui/icons/ViewCarousel";
//Colors
import green from "@material-ui/core/colors/green";
import { Typography } from "@material-ui/core";

import { orderBy } from "lodash";

const useStyles = makeStyles(theme => ({
  root: {
    width: "100%",
    padding: 0,
    backgroundColor: theme.palette.background.paper
  },
  nested: {
    paddingLeft: theme.spacing(4)
  },
  isActive: {
    color: green[300]
  }
}));

const Buildings = props => {
  const { data, errors, id, site, handleNext, updateDataReducer } = props;
  const classes = useStyles();
  //Load data
  if (props.data === "null" || !props.data) {
    updateDataReducer("errors", "Building data not found.");
    props.reroute("/");
    return <div />;
  }

  if (!props.data.length) {
    return <div />;
  }

  if (errors && !props.data) {
    return (
      <Typography variant="h6" align="center">
        {errors}
      </Typography>
    );
  } else if (errors) {
    updateDataReducer("errors", "");
  }

  const buildings = orderBy(data, ["name"], ["asc"]).map(building => {
    return (
      building.siteId === site && (
        <ListItem
          button
          key={building.id}
          onClick={() => handleNext("building", building.id)}
        >
          <ListItemIcon>
            <ViewCarouselIcon
              className={classNames({
                [classes.isActive]: id === building.id
              })}
            />
          </ListItemIcon>
          <ListItemText primary={building.name} secondary={building.address} />
        </ListItem>
      )
    );
  });

  return (
    <List
      component="nav"
      aria-labelledby="nested-list-subheader"
      className={classes.root}
    >
      {Boolean(buildings.length) ? buildings : "No Buildings Found"}
    </List>
  );
};

Buildings.propTypes = {
  data: PropTypes.array.isRequired,
  updateDataReducer: PropTypes.func.isRequired
};

export default Buildings;
