import React, { Component } from "react";
import { compose } from "redux";
import { connect } from "react-redux";

import * as ACTIONS from "../actions";

// MUI
import CircularProgress from "@material-ui/core/CircularProgress";
import Container from "@material-ui/core/Container";
import CssBaseline from "@material-ui/core/CssBaseline";
import LinearProgress from "@material-ui/core/LinearProgress";
import Typography from "@material-ui/core/Typography";
// HOCs
import { withStyles } from "@material-ui/core/styles";

import { isEmpty, orderBy } from "lodash";
import { push } from "connected-react-router";

import BuildingAlarms from "../components/Alarms/BuildingAlarms";

class Alarms extends Component {
  componentDidMount() {
    this.props.getData(this.props.api, "buildings", null, "buildings");
  }

  render() {
    const { buildings, fetching } = this.props;

    if (isEmpty(buildings)) {
      return <LinearProgress />;
    }

    if (fetching) {
      return (
        <Container
          maxWidth="md"
          style={{
            position: "absolute",
            display: "flex",
            top: "50%",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center"
          }}
        >
          <CircularProgress variant="indeterminate" color="primary" />
        </Container>
      );
    }

    return (
      <>
        <CssBaseline />
        <Typography
          variant="h5"
          style={{
            padding: "2rem 0",
            fontVariant: "all-petite-caps",
            display: "flex",
            justifyContent: "center",
            textAlign: "center",
            width: "100%"
          }}
        >
          Building Alarms
        </Typography>
        <Container maxWidth="md" style={{ padding: "0 0 2rem 0" }}>
          {buildings.length &&
            orderBy(buildings, ["name"]).map(building => {
              console.log(building);
              return <BuildingAlarms building={building} key={building.id} />;
            })}
        </Container>
      </>
    );
  }
}

const styles = theme => ({
  root: {
    //
  }
});

export default compose(
  connect(
    state => ({
      api: state.user.api,
      buildings: state.data.buildings,
      fetching: state.data.fetching
    }),
    dispatch => ({
      reroute: route => dispatch(push(route)),
      getData: (api, filter, params, storeAs) =>
        dispatch({
          type: ACTIONS.DATA_SAGA,
          payload: { api, filter, params, storeAs }
        })
    })
  ),
  withStyles(styles)
)(Alarms);
