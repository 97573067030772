import React from "react";

import Container from "@material-ui/core/Container";
import LinearProgress from "@material-ui/core/LinearProgress";
//Hooks
import { makeStyles } from "@material-ui/core/styles";

//Icons
import ViewCarouselIcon from "@material-ui/icons/ViewCarousel"; //Building
import LayersIcon from "@material-ui/icons/Layers"; //Floor
import AspectRatioIcon from "@material-ui/icons/AspectRatio"; //Space
import UsbIcon from "@material-ui/icons/Usb"; //Device
//Colors
import green from "@material-ui/core/colors/green";
//Component
import Point from "../Point";

import { filter, orderBy } from "lodash";
import { Grid, Typography, List, ListItem, Divider } from "@material-ui/core";

const useStyles = makeStyles(theme => ({
  root: {
    width: "100%",
    padding: 0,
    backgroundColor: theme.palette.background.paper
  },
  nested: {
    paddingLeft: theme.spacing(4)
  },
  themeColor: {
    color: theme.palette.primary.main
  },
  isActive: {
    color: green[300]
  }
}));

const buildTree = (
  buildings,
  { buildingId, floorId, spaceId, name },
  classes
) => {
  const building = buildings[0].name; // assign it to some variable
  const floor = filter(buildings[0].floors, v => floorId === v.id)[0].name; // assign it to some variable
  const space = filter(buildings[0].spaces, v => spaceId === v.id)[0].name; // assign it to some variable
  const device = name;

  return [
    {
      label: building,
      icon: <ViewCarouselIcon className={classes.themeColor} />
    },
    {
      label: floor,
      icon: <LayersIcon className={classes.themeColor} />
    },
    {
      label: space,
      icon: <AspectRatioIcon className={classes.themeColor} />
    },
    {
      label: device,
      icon: <UsbIcon className={classes.themeColor} />
    }
  ];
};

const Points = props => {
  const { data, buildings } = props;
  const hideData = true;
  const classes = useStyles();

  //Load data
  if (!props.data.points.length) {
    return <LinearProgress />;
  }

  // eslint-disable-next-line
  const tree = buildTree(buildings, data, classes);

  const generateObjectTree = points => {
    const master = {};

    points.forEach(point => {
      if (!master.hasOwnProperty(point.dataObjectType)) {
        master[point.dataObjectType] = [];
        master[point.dataObjectType].push(point);
      } else {
        master[point.dataObjectType].push(point);
      }
    });

    return master;
  };

  const objectTree = generateObjectTree(data.points);

  return (
    <Container className={classes.root}>
      <Grid
        container
        style={{ padding: "1rem", borderBottom: "1px solid #ccc" }}
      >
        {tree &&
          tree.map((item, idx) => (
            <Grid
              key={`${item.label}_${idx}_`}
              item
              xs={6}
              style={{
                justifyContent: idx % 2 === 1 ? "flex-start" : "flex-end",
                display: "flex",
                alignItems: "center",
                padding: ".25rem 1rem"
              }}
            >
              {idx % 2 === 1 ? (
                <>
                  <div style={{ padding: ".5rem" }}>{item.icon}</div>
                  <div>{item.label}</div>
                </>
              ) : (
                <>
                  <div
                    style={{
                      textAlign: idx === 0 ? "right" : "left",
                      padding: ".5rem"
                    }}
                  >
                    {item.label}
                  </div>
                  <div style={{ padding: ".5rem" }}>{item.icon}</div>
                </>
              )}
            </Grid>
          ))}
      </Grid>
      <Grid container>
        {Object.keys(objectTree).map((key, idx) => {
          return (
            <Grid
              item
              xs={12}
              key={`${key}__${idx}_`}
              style={{ paddingTop: "1rem" }}
            >
              <Typography variant="h6" style={{ textAlign: "center" }}>
                {key}
              </Typography>
              <List>
                {orderBy(objectTree[key], ["readOnly", "name"], ["asc"]).map(
                  (point, idx) => {
                    return (
                      <div key={`${point.id}__${idx}`}>
                        <ListItem
                          style={{ display: "flex", flexDirection: "column" }}
                        >
                          <Point {...point} hideData={hideData} />
                        </ListItem>
                        <Divider light={true} />
                      </div>
                    );
                  }
                )}
              </List>
            </Grid>
          );
        })}
      </Grid>
    </Container>
  );
};

export default Points;
