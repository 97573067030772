import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";

import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import LinearProgress from "@material-ui/core/LinearProgress";
//Hooks
import { makeStyles } from "@material-ui/core/styles";
//Icons
import AspectRatioIcon from "@material-ui/icons/AspectRatio";
//Colors
import green from "@material-ui/core/colors/green";

import { orderBy } from "lodash";

const useStyles = makeStyles(theme => ({
  root: {
    width: "100%",
    padding: 0,
    backgroundColor: theme.palette.background.paper
  },
  nested: {
    paddingLeft: theme.spacing(4)
  },
  isActive: {
    color: green[300]
  }
}));

const getDeviceNames = space => {
  if (!space.devices) return "";

  const types = [];
  space.devices.forEach(device => {
    if (!types.includes(device.name)) {
      types.push(device.name);
    }
  });
  return types;
};

const Spaces = props => {
  const { data, handleNext, id } = props;
  const classes = useStyles();
  //Load data
  if (!props.data.length) {
    return <LinearProgress />;
  }

  const sortedData = orderBy(data, ["name"], ["asc"]);

  return (
    <List
      component="nav"
      aria-labelledby="nested-list-subheader"
      className={classes.root}
    >
      {sortedData.map(space => {
        let deviceNames = getDeviceNames(space).sort();
        return (
          <ListItem
            button
            disabled={!Boolean(space.devices.length)}
            key={space.id}
            onClick={() => handleNext("space", space.id)}
          >
            <ListItemIcon>
              <AspectRatioIcon
                className={classNames({
                  [classes.isActive]: id === space.id
                })}
              />
            </ListItemIcon>
            <ListItemText
              primary={space.name}
              secondary={
                !Boolean(space.devices.length)
                  ? `No Devices`
                  : space.devices.length > 1
                  ? `${space.devices.length} devices: ${deviceNames.join(", ")}`
                  : `1 device: ${deviceNames.join(", ")}`
              }
            />
          </ListItem>
        );
      })}
    </List>
  );
};

Spaces.propTypes = {
  data: PropTypes.array.isRequired
};

export default Spaces;
