import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import { orderBy } from "lodash";

import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import LinearProgress from "@material-ui/core/LinearProgress";
//Hooks
import { makeStyles } from "@material-ui/core/styles";
//Icons
import LayersIcon from "@material-ui/icons/Layers";
//Colors
import green from "@material-ui/core/colors/green";

const useStyles = makeStyles(theme => ({
  root: {
    width: "100%",
    padding: 0,
    backgroundColor: theme.palette.background.paper
  },
  nested: {
    paddingLeft: theme.spacing(4)
  },
  isActive: {
    color: green[300]
  }
}));

const getSpaceNames = floor => {
  if (!floor.spaces) return "";

  const types = [];
  floor.spaces.forEach(space => {
    if (!types.includes(space.name)) {
      types.push(space.name);
    }
  });
  return types;
};

const Floors = props => {
  const { data, handleNext, id } = props;
  const classes = useStyles();

  //Load data
  if (!props.data.length) {
    return <LinearProgress />;
  }

  const sortedData = orderBy(data, ["name"], ["asc"]);

  return (
    <List
      component="nav"
      aria-labelledby="nested-list-subheader"
      className={classes.root}
    >
      {sortedData.map(floor => {
        let spaceNames = getSpaceNames(floor).sort();
        return (
          <ListItem
            button
            disabled={!Boolean(floor.spaces.length)}
            key={floor.id}
            onClick={() => handleNext("floor", floor.id)}
          >
            <ListItemIcon>
              <LayersIcon
                className={classNames({
                  [classes.isActive]: id === floor.id
                })}
              />
            </ListItemIcon>
            <ListItemText
              primary={floor.name}
              secondary={
                !Boolean(floor.spaces.length)
                  ? `No Spaces`
                  : floor.spaces.length > 1
                  ? `${floor.spaces.length} spaces: ${spaceNames.join(", ")}`
                  : `1 space: ${spaceNames.join(", ")}`
              }
            />
          </ListItem>
        );
      })}
    </List>
  );
};

Floors.propTypes = {
  data: PropTypes.array.isRequired
};

export default Floors;
