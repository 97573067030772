import React, { useState } from "react";
import { connect } from "react-redux";
import * as ACTIONS from "../../actions";

// MUI
import { isEmpty } from "lodash";
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import IconButton from "@material-ui/core/IconButton";
import Typography from "@material-ui/core/Typography";
// Icons
import TuneIcon from "@material-ui/icons/Tune"; // Dimmer
import TonalityIcon from "@material-ui/icons/Tonality"; // Sensor
//Status Icons
import BlurOnIcon from "@material-ui/icons/BlurOn"; // Dim Status
import ToggleOnIcon from "@material-ui/icons/ToggleOn"; // Switch / On
import ToggleOffIcon from "@material-ui/icons/ToggleOff"; // Off

// Status Icons
import CheckCircleIcon from "@material-ui/icons/CheckCircle"; // Default
import HelpIcon from "@material-ui/icons/Help"; //Unknown

//HOCs
import { makeStyles } from "@material-ui/core/styles";
//Colors

//Utils
import { idNameTable } from "../../util";
import LightsTable from "./LightsTable";

//Color
import green from "@material-ui/core/colors/green";
import { push } from "connected-react-router";

//Styles
const useStyles = makeStyles(theme => ({
  root: {},
  container: {
    padding: "1rem",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center"
  },
  label: {
    display: "flex",
    justifyContent: "center",
    padding: ".5rem"
  }
}));

const icons = [
  {
    label: "Switches",
    icon: <ToggleOnIcon />,
    value: "lightSwitch"
  },
  {
    label: "Dimmers",
    icon: <TuneIcon />,
    value: "lightDim"
  },

  {
    label: "Sensors",
    icon: <TonalityIcon />,
    value: "lightLevelSensor"
  }
];

const getStatusIcon = status => {
  switch (status) {
    case "UNKNOWN": {
      return <HelpIcon />;
    }

    case "on": {
      return <ToggleOnIcon />;
    }

    case "off": {
      return <ToggleOffIcon />;
    }

    case "dim": {
      return <BlurOnIcon />;
    }

    case true: {
      return <CheckCircleIcon style={{ color: green[300] }} />;
    }

    default: {
      return <CheckCircleIcon style={{ color: green[300] }} />;
    }
  }
};

// Main
const LightsStepper = props => {
  const { data } = props;
  const classes = useStyles();
  const [active, setActive] = useState(["lightSwitch", "lightDim"]);

  if (!data[0]) {
    return <div />;
  }

  let table = {};
  if (isEmpty(props.idNameTable)) {
    table = idNameTable(data);
    props.updateTable(table);
  } else {
    table = props.idNameTable;
  }

  const filterData = (active, data) => {
    const filtered = [];
    data.forEach(building => {
      building.devices.forEach(device => {
        filtered.push(
          device.points.filter(p => active.includes(p.dataObjectType))
        );
      });
    });
    return filtered;
  };

  const handleClick = value => {
    let newArr = [...active];
    if (newArr.includes(value)) {
      newArr = active.filter(a => a !== value);
    } else {
      newArr.push(value);
    }
    setActive(newArr);
  };

  const generatedData = (data, table) => {
    let filteredData = [];
    let filteredDevices = filterData(active, data);

    filteredDevices.forEach(points => {
      if (points) {
        points.forEach(point => {
          filteredData.push({
            icon: getStatusIcon(point.status),
            point: point.name,
            device: table.devices[point.deviceId],
            building: table.buildings[point.buildingId],
            floor: table.floors[point.floorId],
            space: table.spaces[point.spaceId],
            data: point
          });
        });
      }
    });

    return filteredData;
  };

  const lightsTable = generatedData(data, table);

  // Render
  return (
    <Container maxWidth="md" className={classes.root}>
      <Grid container>
        {icons.map(icon => {
          return (
            <Grid key={icon.label} item xs={4} className={classes.container}>
              <IconButton
                onClick={() => handleClick(icon.value)}
                style={{
                  background: active.includes(icon.value)
                    ? "#BCE0FD"
                    : "#CCCCCC"
                }}
              >
                {icon.icon}
              </IconButton>
              <Typography variant="subtitle1" className={classes.label}>
                {icon.label}
              </Typography>
            </Grid>
          );
        })}
      </Grid>
      <LightsTable data={lightsTable} reroute={props.reroute} title="Lights" />
    </Container>
  );
};

export default connect(
  state => ({
    idNameTable: state.constants.idNameTable
  }),
  dispatch => ({
    reroute: route => dispatch(push(route)),
    updateTable: payload =>
      dispatch({
        type: ACTIONS.UPDATE_CONSTANT_ASPECT,
        aspect: "idNameTable",
        payload
      })
  })
)(LightsStepper);
