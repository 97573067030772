import React from "react";

//Routes
import { Route, Switch } from "react-router";

//Code Splitting
// import { asyncComponent } from "../hocs/asyncComponent";

//Components
import Navigation from "../components/Navigation";
import Footer from "../components/Footer";
import Landing from "../pages/Landing";
import Alarms from "../pages/Alarms";
import Calendars from "../pages/Calendars";
import Climate from "../pages/Climate";
import History from "../pages/History";
import Lights from "../pages/Lights";
import Dashboard from "../pages/Dashboard";
import PointPage from "../pages/PointPage";
import Profile from "../pages/Profile";
import DeviceAccess from "../pages/DeviceAccess";

//SUITES
//Auth
export const LANDING = "/";
export const ALARMS = "/alarms";
export const CALENDARS = "/calendars";
export const CLIMATE = "/climate";
export const DASHBOARD = "/dashboard";
export const DEVICE_ACCESS = "/deviceaccess";
export const HISTORY = "/history";
export const LIGHTS = "/lights";
export const POINT = "/point";
export const PROFILE = "/profile";

//Routes Object
const routes = (
  <div style={{ background: "white" }}>
    <Navigation />
    <Switch>
      <Route exact path={DASHBOARD} component={Dashboard} />
      <Route exact path={`${ALARMS}/:site`} component={Alarms} />
      <Route exact path={`${CALENDARS}/:site`} component={Calendars} />
      <Route exact path={`${CLIMATE}/:site`} component={Climate} />
      <Route exact path={`${HISTORY}/:site`} component={History} />
      <Route exact path={`${DEVICE_ACCESS}/:site`} component={DeviceAccess} />
      <Route exact path={LANDING} component={Landing} />
      <Route exact path={`${LIGHTS}/:site`} component={Lights} />
      <Route
        exact
        path={`${POINT}/:buildingId/:floorId/:spaceId/:deviceId/:id`}
        component={PointPage}
      />
      <Route exact path={PROFILE} component={Profile} />
    </Switch>
    <Footer />
  </div>
);

export default routes;
