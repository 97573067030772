import React, { Component } from "react";
import PropTypes from "prop-types";
import { compose } from "redux";
import { connect } from "react-redux";
import { push } from "connected-react-router";
import * as ACTIONS from "../actions";

//MUI
import Container from "@material-ui/core/Container";
import FormControl from "@material-ui/core/FormControl";
import Grid from "@material-ui/core/Grid";
import InputBase from "@material-ui/core/InputBase";
import InputLabel from "@material-ui/core/InputLabel";
import NativeSelect from "@material-ui/core/NativeSelect";
//Icons
import SearchIcon from "@material-ui/icons/Search";
import WhatshotIcon from "@material-ui/icons/Whatshot";
import WbIncandescentOutlinedIcon from "@material-ui/icons/WbIncandescentOutlined";
import EventIcon from "@material-ui/icons/Event";
import WarningIcon from "@material-ui/icons/Warning";
import PollIcon from "@material-ui/icons/Poll";
import BatteryChargingFullIcon from "@material-ui/icons/BatteryChargingFull";
import ChromeReaderModeIcon from "@material-ui/icons/ChromeReaderMode";
import MemoryIcon from "@material-ui/icons/Memory";
import AddAlarmIcon from "@material-ui/icons/AddAlarm";
import SecurityIcon from "@material-ui/icons/Security";
//HOCS
import { withStyles } from "@material-ui/core/styles";
import { IconButton, Typography, LinearProgress } from "@material-ui/core";
import { DEVICE_ACCESS } from "../routes";

import Fetching from "../components/Fetching";

import { isEmpty } from "lodash";

const dashboardIcons = () => {
  return [
    {
      name: "Climate",
      icon: <WhatshotIcon />,
      value: "/climate"
    },
    {
      name: "Lights",
      icon: <WbIncandescentOutlinedIcon />,
      value: "/lights"
    },
    {
      name: "Calendar",
      icon: <EventIcon />,
      value: "/calendars"
    },
    {
      name: "Alarms",
      icon: <WarningIcon />,
      value: "/alarms"
    },
    {
      name: "History",
      icon: <ChromeReaderModeIcon />,
      value: "/history"
    },
    {
      name: "Analytics",
      icon: <PollIcon />,
      value: "/analytics"
    },
    {
      name: "Energy",
      icon: <BatteryChargingFullIcon />,
      value: "/energy"
    },

    {
      name: "ML",
      icon: <MemoryIcon />,
      value: "/ml"
    },
    {
      name: "Schedule",
      icon: <AddAlarmIcon />,
      value: "/schedule"
    },
    {
      name: "Security",
      icon: <SecurityIcon />,
      value: "/security"
    }
  ];
};

const BootstrapInput = withStyles(theme => ({
  root: {
    "label + &": {
      marginTop: theme.spacing(3)
    }
  },
  input: {
    borderRadius: 4,
    position: "relative",
    backgroundColor: theme.palette.background.paper,
    border: "1px solid #ced4da",
    fontSize: 16,
    padding: "10px 26px 10px 12px",
    transition: theme.transitions.create(["border-color", "box-shadow"]),
    "&:focus": {
      borderRadius: 4,
      borderColor: "#80bdff",
      boxShadow: "0 0 0 0.2rem rgba(0,123,255,.25)"
    }
  }
}))(InputBase);

class Dashboard extends Component {
  constructor(props) {
    super();
    this.state = {
      site: props.selectedSite
    };
  }

  componentDidMount() {
    if (!this.props.didFetchUser) {
      this.props.reroute("/");
    } else {
      this.props.getData(this.props.api, "clients", null, "clients");
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      prevState.site === "" &&
      this.props.clients[0] &&
      this.props.clients[0].client
    ) {
      if (
        this.props.clients[0] &&
        this.props.clients[0].client.sites.length &&
        this.props.selectedSite === "" &&
        this.state.site === ""
      ) {
        this.props.setSite(
          this.props.clients[0] && this.props.clients[0].client.sites[0].id
        );
      }
    }

    if (prevState.site !== this.state.site) {
      this.setState({ site: this.props.selectedSite });
    }
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    if (nextProps.selectedSite !== prevState.site) {
      return { site: nextProps.selectedSite };
    } else return null;
  }

  render() {
    const { classes, clients, didFetchUser, fetching } = this.props;
    const { site } = this.state;
    const active = ["/climate", "/lights", "/calendars", "/alarms", "/history"];
    const dashboardLayout = dashboardIcons();

    if (!didFetchUser || isEmpty(clients)) {
      return <LinearProgress color="secondary" />;
    }

    Fetching(fetching);

    const sites = this.generateSites(clients[0].client.sites, classes);
    console.log(site);

    return (
      <Container maxWidth="md">
        {/* Different for a single site */}
        {clients[0].client.sites.length === 1 ? (
          <Grid item xs={12} className={classes.container}>
            <Typography variant="h5" className={classes.siteTitle}>
              {clients[0].client.sites[0].name} Dashboard
            </Typography>
          </Grid>
        ) : (
          <Grid item xs={12} className={classes.container}>
            {sites}
          </Grid>
        )}

        <div className={classes.divider} />
        {site && (
          <>
            <Grid item xs={12} className={classes.container}>
              <IconButton
                style={{ border: "1px solid lightblue" }}
                onClick={() => this.props.reroute(`${DEVICE_ACCESS}/${site}`)}
              >
                <SearchIcon />
              </IconButton>
              <Typography variant="subtitle1" className={classes.label}>
                Navigation
              </Typography>
            </Grid>
            <Grid
              container
              style={{ justifyContent: "space-evenly", padding: "1rem" }}
            >
              {dashboardLayout.map(icon => {
                return (
                  <Grid
                    key={icon.name}
                    item
                    xs={4}
                    sm={3}
                    className={classes.container}
                  >
                    <IconButton
                      disabled={!active.includes(icon.value)}
                      onClick={() =>
                        this.props.reroute(`${icon.value}/${site}`)
                      }
                      style={{
                        background: active.includes(icon.value)
                          ? "#BCE0FD"
                          : "#CCCCCC"
                      }}
                    >
                      {icon.icon}
                    </IconButton>
                    <Typography variant="subtitle1" className={classes.label}>
                      {icon.name}
                    </Typography>
                  </Grid>
                );
              })}
            </Grid>
          </>
        )}
      </Container>
    );
  }

  generateSites(sites, classes) {
    const { site } = this.state;

    const handleChange = event => {
      this.props.setSite(event.target.value);
    };

    if (sites.length === 1) {
      return (
        <form className={classes.root} autoComplete="off">
          <FormControl className={classes.margin} disabled={true}>
            <InputLabel htmlFor="site-native-simple">SITES</InputLabel>
            <NativeSelect
              value={site}
              onChange={handleChange}
              input={<BootstrapInput name="site" id="site-native-simple" />}
            >
              <option value={sites[0].id}>{sites[0].name}</option>
            </NativeSelect>
          </FormControl>
          <Typography variant="h6">Select Your Site</Typography>
        </form>
      );
    } else if (sites.length > 1) {
      return (
        <form className={classes.root} autoComplete="off">
          <FormControl className={classes.margin}>
            <InputLabel htmlFor="site-native-simple">SITES</InputLabel>
            <NativeSelect
              value={site}
              onChange={handleChange}
              input={<BootstrapInput name="site" id="site-native-simple" />}
            >
              <option value={""} />

              {sites.map((site, idx) => {
                return (
                  <option key={sites[idx].id} value={sites[idx].id}>
                    {sites[idx].name}
                  </option>
                );
              })}
            </NativeSelect>
          </FormControl>
          <Typography variant="h6">Select Your Site</Typography>
        </form>
      );
    } else {
      return null;
    }
  }
}

const styles = theme => ({
  root: {},
  container: {
    padding: "1rem",
    textAlign: "center",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center"
  },
  label: {
    display: "flex",
    justifyContent: "center",
    padding: ".5rem"
  },
  divider: {
    margin: "2rem 0",
    width: "100%"
  },
  siteTitle: {
    marginTop: "1rem",
    textAlign: "center",
    color: theme.palette.primary.dark
  }
});

Dashboard.propTypes = {
  classes: PropTypes.object.isRequired
};

export default compose(
  connect(
    state => ({
      api: state.user.api,
      clients: state.data.clients,
      didFetchUser: state.user.didFetchUser,
      fetching: state.data.fetching,
      selectedSite: state.data.selectedSite
    }),
    dispatch => ({
      reroute: route => dispatch(push(route)),
      getData: (api, filter, params, storeAs) =>
        dispatch({
          type: ACTIONS.DATA_SAGA,
          payload: { api, filter, params, storeAs }
        }),
      setSite: site =>
        dispatch({
          type: ACTIONS.UPDATE_DATA_ASPECT,
          aspect: "selectedSite",
          payload: site
        })
    })
  ),
  withStyles(styles)
)(Dashboard);
