import React from "react";
import * as moment from "moment";

import {
  AreaChart,
  Area,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer
} from "recharts";

const CustomTooltip = ({ payload, label, active }) => {
  if (active) {
    let value = ``;
    if (Number.isNaN(Number(payload[0].value))) {
      value = payload[0].value;
    } else {
      value = `${Number(payload[0].value).toFixed(1)}°`;
    }

    return (
      <div className="custom-tooltip">
        <p
          className="label"
          style={{
            fontWeight: "bold",
            fontSize: "1.25rem",
            lineHeight: 0.5,
            margin: "0px inherit"
          }}
        >
          {value}
        </p>
        <p className="label" style={{ lineHeight: 0.1 }}>{`${moment(
          label
        ).format("MMM Do")}`}</p>
        <p className="label">{`${moment(label).format("hh:mm:ss A")}`}</p>
      </div>
    );
  }

  return null;
};

const HistoryChart = ({ data }) => {
  const ticks = [
    new Date(data[0].timestamp),
    new Date(data[data.length - 1].timestamp)
  ];

  return (
    <div style={{ width: "100%", height: 300 }}>
      <ResponsiveContainer>
        <AreaChart
          data={data}
          margin={{
            top: 10,
            right: 30,
            left: 0,
            bottom: 0
          }}
        >
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis
            dataKey="timestamp"
            // tickFormatter={timeFormatter}
            tickFormatter={unixTime => moment(unixTime).format("MMM Do")}
            scale="time"
            ticks={ticks}
          />
          <YAxis
            type={Number.isNaN(Number(data[0].value)) ? "category" : "number"}
          />
          <Tooltip content={<CustomTooltip />} />
          <Area
            type="monotone"
            dataKey="value"
            stroke="#8884d8"
            fill="#8884d8"
          />
        </AreaChart>
      </ResponsiveContainer>
    </div>
  );
};

export default HistoryChart;
