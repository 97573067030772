import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Drawer from "@material-ui/core/Drawer";
import Button from "@material-ui/core/Button";
import List from "@material-ui/core/List";
import Divider from "@material-ui/core/Divider";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import ListSubheader from "@material-ui/core/ListSubheader";

import { isEmpty } from "lodash";
import { Typography, LinearProgress } from "@material-ui/core";

import { functions } from "../../firebase/firebase";

import * as moment from "moment";

// Icons
import ErrorIcon from "@material-ui/icons/Error";
import NotificationsIcon from "@material-ui/icons/Notifications";
import WarningIcon from "@material-ui/icons/Warning";

const useStyles = makeStyles({
  list: {
    width: 250
  },
  fullList: {
    width: "auto"
  }
});

export default function AlarmDrawer(props) {
  const classes = useStyles();
  const { open, setOpen, drawerData, reroute, api } = props;
  const [logs, setLogs] = React.useState({ alarms: [] });
  const [didFetch, setDidFetch] = React.useState(false);
  const [updating, setUpdating] = React.useState(false);

  const toggleDrawer = open => event => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    setOpen(open);
  };

  React.useEffect(() => {
    if (!open) {
      setDidFetch(false);
      setLogs({ alarms: [] });
    }
  }, [open]);

  const handleReroute = () => {
    let { buildingId, floorId, spaceId, deviceId, pointId } = drawerData;

    reroute(
      `/point/${buildingId}/${floorId}/${spaceId}/${deviceId}/${pointId}`
    );
  };

  const fetchDeviceAlarms = functions.httpsCallable("fetchEndpoint");

  if (
    !Boolean(logs.alarms.length) &&
    drawerData.deviceId &&
    !didFetch &&
    open
  ) {
    setUpdating(true);
    setDidFetch(true);
    fetchDeviceAlarms({
      route: `alarm-history/device/${drawerData.deviceId}`,
      api
    })
      .then(result => {
        if (!isEmpty(result.data)) {
          setLogs(JSON.parse(result.data));
          setUpdating(false);
        } else {
          setLogs({ alarms: [] });
          setUpdating(false);
        }
      })
      .catch(e => {
        setLogs({ alarms: [] });
        setUpdating(false);
        console.log(e);
      });
  }

  //   Getting logs
  if (didFetch && updating) {
    return (
      <div>
        <Drawer anchor="right" open={open} onClose={toggleDrawer(false)}>
          <List
            subheader={
              <ListSubheader
                component="div"
                id="nested-list-subheader"
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  flexDirection: "column"
                }}
              >
                {drawerData.deviceName} Alarm Logs
                <Button
                  style={{ marginBottom: "1rem" }}
                  variant="contained"
                  color="primary"
                  onClick={() => handleReroute()}
                >
                  Go To Point
                </Button>
              </ListSubheader>
            }
          >
            <Divider />
            <LinearProgress />
            <Typography
              variant="inherit"
              align="center"
              style={{ padding: "1rem" }}
            >
              Fetching {drawerData.deviceName} logs...
            </Typography>
          </List>
        </Drawer>
      </div>
    );
  }

  //   No Logs
  if (didFetch && !updating && !logs.alarms.length) {
    return (
      <div>
        <Drawer anchor="right" open={open} onClose={toggleDrawer(false)}>
          <List
            subheader={
              <ListSubheader
                component="div"
                id="nested-list-subheader"
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  flexDirection: "column"
                }}
              >
                {drawerData.deviceName} Alarm Logs
                <Button
                  style={{ marginBottom: "1rem" }}
                  variant="contained"
                  color="primary"
                  onClick={() => handleReroute()}
                >
                  Go To Point
                </Button>
              </ListSubheader>
            }
          >
            <Divider />

            <Typography variant="h6" align="center" style={{ padding: "1rem" }}>
              No Alarm History
            </Typography>
          </List>
        </Drawer>
      </div>
    );
  }

  //   Some Logs
  const alarmLogs = (
    <div
      className={classes.list}
      role="presentation"
      onClick={toggleDrawer(false)}
      onKeyDown={toggleDrawer(false)}
    >
      <List
        subheader={
          <ListSubheader
            component="div"
            id="nested-list-subheader"
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              flexDirection: "column",
              background: "white",
              borderBottom: "1px solid #ccc"
            }}
          >
            {drawerData.deviceName} Alarm Logs
            <Button
              style={{ marginBottom: "1rem" }}
              variant="contained"
              color="primary"
              onClick={() => handleReroute()}
            >
              Go To Point
            </Button>
          </ListSubheader>
        }
      >
        <Divider />

        {logs.alarms.map((alarm, index) => (
          <ListItem key={alarm.GUID}>
            <ListItemIcon>
              {alarm.state === "ALARM" ? (
                <WarningIcon style={{ color: "#F44336" }} />
              ) : !alarm.acknowledged ? (
                <ErrorIcon />
              ) : (
                <NotificationsIcon />
              )}
            </ListItemIcon>
            <ListItemText
              primary={alarm.alarmMessage}
              secondary={
                <span style={{ display: "flex", flexDirection: "column" }}>
                  <span>
                    {moment(alarm.startTime * 1000).format("MM/DD/YYYY")}
                  </span>
                  <span>{moment(alarm.startTime * 1000).format("h:mm A")}</span>
                </span>
              }
            />
          </ListItem>
        ))}
      </List>
    </div>
  );

  return (
    <div>
      <Drawer anchor="right" open={open} onClose={toggleDrawer(false)}>
        {alarmLogs}
      </Drawer>
    </div>
  );
}
