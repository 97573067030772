// eslint-disable-next-line
import * as ACTIONS from "../actions";

const INITIAL_STATE = {
  endpoint: {
    root: "https://api.tapa.cc",
    clients: "/clients",
    buildings: "/buildings",
    building: "/building/",
    devices: "/devices/",
    points: "/points/"
  },
  catalog: [
    "/clients",
    "/clients/{clientId}",
    "/buildings",
    "/building/{buildingId}",
    "/buildings/{buildingId}/devices",
    "/buildings/{buildingId}/devices/{deviceType}",
    "/buildings/{buildingId}/spaces/{spaceId}/devices",
    "/buildings/{buildingId}/spaces/{spaceId}/devices/{deviceType}",
    "/buildings/{buildingId}/floors/{floorId}/devices",
    "/buildings/{buildingId}/floors/{floorId}/devices/{deviceType}",
    "/devices/{deviceId}",
    "/devices/{deviceId}/points/{pointId}",
    "/points/{pointId}"
  ],
  objectTypes: [
    "temperature",
    "lightDim",
    "lightSwitch",
    "calendar",
    "schedule",
    "securityLog",
    "securityDoor",
    "securityCamera",
    "vfd",
    "heatingCooling",
    "airFilter",
    "airFlow",
    "airQuality",
    "meter",
    "occupancySensor",
    "lightLevelSensor"
  ],
  idNameTable: {}
};

function constantsReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    //Generic Updates
    case ACTIONS.UPDATE_CONSTANT_ASPECT: {
      return { ...state, [`${action.aspect}`]: action.payload };
    }

    case ACTIONS.UPDATE_CONSTANT_REDUCER: {
      return { ...state, ...action.payload };
    }

    default:
      return state;
  }
}

export default constantsReducer;
