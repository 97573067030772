import React from "react";
import Container from "@material-ui/core/Container";
import CircularProgress from "@material-ui/core/CircularProgress";

const Fetching = ({ fetching }) => {
  return fetching ? (
    <Container
      maxWidth="md"
      style={{
        position: "absolute",
        display: "flex",
        top: "50%",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center"
      }}
    >
      <CircularProgress variant="indeterminate" color="primary" />
    </Container>
  ) : (
    <div />
  );
};

export default Fetching;
