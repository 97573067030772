import React from "react";
import { compose } from "redux";
import { connect } from "react-redux";
import CircularProgress from "@material-ui/core/CircularProgress";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
//Icons
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import CancelIcon from "@material-ui/icons/Cancel";

//HOCS
import { withStyles } from "@material-ui/core/styles";
import { green, deepOrange } from "@material-ui/core/colors";

//Components
import CustomSwitch from "../CustomSwitch";

const getStatusIcon = state => {
  switch (state.currentState) {
    case "updating": {
      return (
        <CircularProgress variant="indeterminate" color="primary" size={20} />
      );
    }

    case "normal": {
      return <CheckCircleIcon style={{ color: green[400] }} />;
    }

    case "error": {
      return <CancelIcon style={{ color: deepOrange[400] }} />;
    }

    default: {
      return <CheckCircleIcon />;
    }
  }
};

const Status = props => {
  const { classes, data, api, id, name, state, readOnly } = props;

  if (readOnly) {
    return (
      <List disablePadding className={classes.container}>
        <ListItem dense className={classes.displayContainer}>
          <ListItemText primary={name} secondary={data.value ? "On" : "Off"} />
          <ListItemIcon className={classes.icon}>
            {getStatusIcon(state)}
          </ListItemIcon>
        </ListItem>
      </List>
    );
  } else if (typeof readOnly === "boolean" && readOnly === false) {
    return (
      <List disablePadding className={classes.container}>
        <ListItem dense className={classes.displayContainer}>
          <ListItemText
            primary={name}
            secondary={data.value ? "On" : "Off"}
            className={classes.display}
          />
          <CustomSwitch
            id={id}
            deviceId={props.deviceId}
            api={api}
            data={data}
          />
          <ListItemIcon
            className={classes.icon}
            classes={{ root: classes.iconRoot }}
          >
            {getStatusIcon(state)}
          </ListItemIcon>
        </ListItem>
      </List>
    );
  } else {
    return <div />;
  }
};

const styles = theme => ({
  root: {
    minWidth: "unset",
    marginLeft: "1rem"
  },
  container: {
    margin: "2rem 0",
    justifyContent: "space-evenly"
  },
  icon: {
    justifyContent: "flex-end"
  },
  displayContainer: {
    display: "flex",
    justifyContent: "space-evenly",
    padding: 0,
    [theme.breakpoints.down("xs")]: {
      padding: "0 1rem"
    }
  },
  display: {
    width: "150px",
    display: "flex",
    flexDirection: "column"
  }
});

export default compose(
  connect(state => ({
    objectTypes: state.constants.objectTypes,
    api: state.user.api
  })),
  withStyles(styles)
)(Status);
