import React, { useEffect, useState } from "react";
import { compose } from "redux";
import { connect } from "react-redux";

import CircularProgress from "@material-ui/core/CircularProgress";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";

//Icons
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import CancelIcon from "@material-ui/icons/Cancel";

//HOCS
import { withStyles } from "@material-ui/core/styles";
import { green, deepOrange } from "@material-ui/core/colors";

//Components
import Slide from "../Slider/Slide";

const getStatusIcon = currentState => {
  switch (currentState) {
    case "updating": {
      return (
        <CircularProgress variant="indeterminate" color="primary" size={20} />
      );
    }

    case "normal": {
      return <CheckCircleIcon style={{ color: green[400] }} />;
    }

    case "error": {
      return <CancelIcon style={{ color: deepOrange[400] }} />;
    }

    default: {
      return <CheckCircleIcon />;
    }
  }
};

const LightDimmer = props => {
  const {
    classes,
    api,
    data,
    id,
    deviceId,
    name,
    readOnly,
    state,
    getData,
    hideData
  } = props;

  const { displayString, value } = data;
  const [ready, setReady] = useState(false);
  const [wsClient, setWsClient] = useState();
  const [needsUpdate, setNeedsUpdate] = useState(false);
  const [parentData, setParentData] = useState({
    displayString,
    state,
    value
  });

  useEffect(() => {
    setParentData({
      displayString,
      state,
      value
    });
  }, [displayString, state, value]);

  // Set up socket
  if (!wsClient && !ready) {
    const client = new WebSocket(
      "wss://ptxrcj27wa.execute-api.us-east-1.amazonaws.com/v1"
    );

    client.onopen = evt => {
      setReady(true);
      setWsClient(client);
    };

    client.onclose = evt => {
      setWsClient();
    };

    client.onerror = evt => {
      setWsClient();
      client.close();
    };
  }

  if (ready && needsUpdate && wsClient) {
    // Socket
    wsClient.send(
      JSON.stringify({
        action: "subscribe",
        devices: [deviceId]
      })
    );

    wsClient.onmessage = evt => {
      if (evt.data) {
        const data = JSON.parse(evt.data);
        if (data.data) {
          if (data.id === id) {
            getData(api, "buildings", null, "buildings");
          }
        }
      }
    };
  }

  if (data.status === "unknown") {
    return <div />;
  }

  if (readOnly) {
    return (
      <List disablePadding className={classes.container}>
        <ListItem dense className={classes.adjustablePadding}>
          <ListItemText primary={name} secondary={parentData.displayString} />
          <ListItemIcon className={classes.icon}>
            {getStatusIcon(parentData.state.currentState)}
          </ListItemIcon>
        </ListItem>
      </List>
    );
  } else if (typeof readOnly === "boolean" && readOnly === false) {
    return (
      <List disablePadding className={classes.container}>
        <ListItem dense className={classes.displayContainer}>
          <ListItemText
            primary={name}
            secondary={hideData ? "" : parentData.displayString}
            className={classes.display}
          />
          <Slide
            api={api}
            deviceId={deviceId}
            domain={[0, 100]}
            id={id}
            state={parentData.state}
            value={parentData.value}
            needsUpdate={needsUpdate}
            setNeedsUpdate={setNeedsUpdate}
            gradient={["#93A5CF", "#E4EFE9"]}
            disableNumbers={hideData}
            wsClient={wsClient}
          />
          <ListItemIcon
            className={classes.icon}
            classes={{ root: classes.iconRoot }}
          >
            {getStatusIcon(parentData.state.currentState)}
          </ListItemIcon>
        </ListItem>
      </List>
    );
  } else {
    return <div />;
  }
};

const styles = theme => ({
  root: {
    minWidth: "unset",
    marginLeft: "1rem"
  },
  container: {
    margin: "1rem 0",
    justifyContent: "space-evenly"
  },
  icon: {
    justifyContent: "flex-end"
  },
  adjustablePadding: {
    padding: 0,
    [theme.breakpoints.down("xs")]: {
      padding: "0 1rem"
    }
  },
  displayContainer: {
    display: "flex",
    justifyContent: "space-evenly",
    padding: 0,
    [theme.breakpoints.down("xs")]: {
      padding: "0 1rem"
    }
  },
  display: {
    width: "150px",
    display: "flex",
    flexDirection: "column"
  }
});

export default compose(
  connect(state => ({
    objectTypes: state.constants.objectTypes,
    api: state.user.api
  })),
  withStyles(styles)
)(LightDimmer);
