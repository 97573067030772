import React, { Component } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import { compose } from "redux";
import { connect } from "react-redux";

//MUI
import Button from "@material-ui/core/Button";
import Card from "@material-ui/core/Card";
import CardHeader from "@material-ui/core/CardHeader";
import CardContent from "@material-ui/core/CardContent";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";

import { withStyles } from "@material-ui/core/styles";

//Mask
import { push } from "connected-react-router";
import { LANDING } from "../routes";

import { updateUser } from "../firebase/db";

class Profile extends Component {
  constructor(props) {
    super();
    this.state = {
      email: props.profile.email,
      firstName: props.profile.firstName,
      lastName: props.profile.lastName,
      api: props.api
    };
  }

  componentDidMount() {
    const { authUser, reroute } = this.props;
    if (!authUser) {
      reroute(LANDING);
      return null;
    }
  }

  render() {
    const { classes } = this.props;

    return (
      <Grid container style={{ justifyContent: "flex-end", position: "relative" }}>
        <Card className={classes.card}>
          <CardHeader
            className={classNames(classes.cardHeader)}
            title={"Menu"}
            subheader={"You're logged in! Navigate below to continue."}
          />
          {/* Default */}
          <CardContent className={classNames(classes.cardContent)}>
            <Grid container>
              <Grid item xs={12}>
                <TextField
                  className={classNames(classes.textField, classes.margin)}
                  required
                  variant="outlined"
                  fullWidth
                  value={this.state.firstName}
                  label={"First Name"}
                  // eslint-disable-next-line
                  InputProps={{
                    value: this.state.firstName,
                    onChange: this.handleChange("firstName"),
                    classes: {
                      root: classes.cssOutlinedInput,
                      focused: classes.cssFocused,
                      notchedOutline: classes.notchedOutline
                    }
                  }}
                  InputLabelProps={{
                    classes: {
                      animated: classes.labelProps,
                      root: classes.cssLabel,
                      focused: classes.cssFocused
                    }
                  }}
                />
              </Grid>

              <Grid item xs={12}>
                <TextField
                  className={classNames(classes.textField, classes.margin)}
                  required
                  variant="outlined"
                  fullWidth
                  value={this.state.lastName}
                  label={"Last Name"}
                  // eslint-disable-next-line
                  InputProps={{
                    value: this.state.lastName,
                    onChange: this.handleChange("lastName"),
                    classes: {
                      root: classes.cssOutlinedInput,
                      focused: classes.cssFocused,
                      notchedOutline: classes.notchedOutline
                    }
                  }}
                  InputLabelProps={{
                    classes: {
                      animated: classes.labelProps,
                      root: classes.cssLabel,
                      focused: classes.cssFocused
                    }
                  }}
                />
              </Grid>

              <Grid item xs={12}>
                <TextField
                  className={classNames(classes.textField, classes.margin)}
                  required
                  variant="outlined"
                  value={this.state.email}
                  label={"Email"}
                  // eslint-disable-next-line
                  InputProps={{
                    value: this.state.email,
                    onChange: this.handleChange("email"),
                    classes: {
                      root: classes.cssOutlinedInput,
                      focused: classes.cssFocused,
                      notchedOutline: classes.notchedOutline
                    }
                  }}
                  InputLabelProps={{
                    classes: {
                      animated: classes.labelProps,
                      root: classes.cssLabel,
                      focused: classes.cssFocused
                    }
                  }}
                />
              </Grid>

              <Grid item xs={12}>
                <TextField
                  className={classNames(classes.textField, classes.margin)}
                  variant="outlined"
                  value={this.state.api}
                  label={"API"}
                  // eslint-disable-next-line
                  InputProps={{
                    value: this.state.api,
                    onChange: this.handleChange("api"),
                    classes: {
                      root: classes.cssOutlinedInput,
                      focused: classes.cssFocused,
                      notchedOutline: classes.notchedOutline
                    }
                  }}
                  InputLabelProps={{
                    classes: {
                      animated: classes.labelProps,
                      root: classes.cssLabel,
                      focused: classes.cssFocused
                    }
                  }}
                />
              </Grid>
            </Grid>
          </CardContent>

          <Button
            onClick={() => this.handleUpdateProfile()}
            fullWidth
            color="secondary"
            variant="contained"
            className={classes.flipButton}>
            <Typography className={classes.bottomButton}>update profile</Typography>
          </Button>
        </Card>
      </Grid>
    );
  }

  handleChange = name => event => {
    this.setState({
      [name]: event.target.value
    });
  };

  handleUpdateProfile = () => {
    const { authUser } = this.props;
    const { api, email, firstName, lastName } = this.state;
    const payload = {
      api,
      profile: {
        email,
        firstName,
        lastName
      }
    };
    updateUser({ uid: authUser.uid, payload });
  };
}

const mapStateToProps = state => ({
  authUser: state.session.authUser,
  profile: state.user.profile,
  api: state.user.api
});

const mapDispatchToProps = dispatch => ({
  reroute: route => {
    dispatch(push(route));
  }
});

const styles = theme => ({
  card: {
    height: 550,
    maxWidth: 345,
    padding: "2em",
    margin: "2em",
    position: "relative",
    borderRadius: 0
  },
  cardHeader: {
    height: "100px"
  },
  cardContent: {
    height: 400,
    position: "relative"
  },
  menuItem: {
    paddingBottom: "1.5em"
  },
  margin: {
    margin: "1em 0"
  },
  button: {
    display: "flex",
    justifyContent: "space-between",
    backgroundColor: theme.palette.secondary.light,
    borderRadius: 0,
    padding: "1em"
  },
  bottomButton: {
    fontSize: "1em",
    letterSpacing: ".2em",
    color: "white"
  },
  flipButton: {
    justifyContent: "center",
    padding: "1em 2em",
    borderRadius: 0,
    position: "absolute",
    bottom: 0,
    left: 0
  }
});

Profile.propTypes = {
  classes: PropTypes.object.isRequired
};

export default compose(
  withStyles(styles),
  connect(
    mapStateToProps,
    mapDispatchToProps
  )
)(Profile);
