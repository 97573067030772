// Hashes id -> name
export const idNameTable = buildings => {
  let table = { buildings: {}, floors: {}, spaces: {}, devices: {} };

  buildings.forEach(building => {
    table.buildings[building.id] = building.name;
  });

  buildings.forEach(building => {
    building.floors.forEach(floor => {
      table.floors[floor.id] = floor.name;
    });

    building.spaces.forEach(space => {
      table.spaces[space.id] = space.name;
    });

    building.devices.forEach(device => {
      table.devices[device.id] = device.name;
    });
  });

  return table;
};
