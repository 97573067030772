import React, { Component } from "react";
import { connect } from "react-redux";

//Firebase
import { firebase } from "../firebase";
import { push } from "connected-react-router";
import { LANDING } from "../routes";

const withAuthentication = () => AuthorizedComponent => {
  class WithAuthentication extends Component {
    //Lifecycle
    componentDidMount() {
      const { onSetAuthUser, reroute } = this.props;

      //If Signing up, bypass AuthStateChanged
      firebase.auth.onAuthStateChanged(authUser => {
        authUser ? onSetAuthUser(authUser) : onSetAuthUser(null);
        if (!authUser) {
          reroute(LANDING);
        }
      });
    }

    render() {
      return <AuthorizedComponent {...this.props} />;
    }
  }

  //Connect
  const mapDispatchToProps = dispatch => ({
    onSetAuthUser: authUser => dispatch({ type: "AUTH_USER_SET", authUser }),
    reroute: route => dispatch(push(route))
  });

  return connect(
    null,
    mapDispatchToProps
  )(WithAuthentication);
};

export default withAuthentication;
