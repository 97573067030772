import React from "react";
import PropTypes from "prop-types";
/* eslint-disable */

const SecurityCamera = props => {
  const { tag, status, state } = props;
  return <div>Security Camera</div>;
};

SecurityCamera.propTypes = {
  tag: PropTypes.string.isRequired,
  status: PropTypes.string.isRequired,
  state: PropTypes.string.isRequired
};

export default SecurityCamera;
