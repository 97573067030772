import firebase from "firebase";

import "firebase/auth/dist/index.cjs";
import "firebase/database/dist/index.cjs";

const defaultConfig = {
  apiKey: "AIzaSyBglASQSMgSnJ3_JkwUwbxUHzVS1CP0xQA",
  authDomain: "tapa-demo.firebaseapp.com",
  databaseURL: "https://tapa-demo.firebaseio.com",
  projectId: "tapa-demo",
  storageBucket: "tapa-demo.appspot.com",
  messagingSenderId: "299159431822",
  appId: "1:299159431822:web:2ebd19f4303e535c"
};

if (!firebase.apps.length) {
  firebase.initializeApp(defaultConfig);
}

//Use Admin SDK
const auth = firebase.auth();
const db = firebase.database();
const functions = firebase.functions();
const store = firebase.firestore();
const storage = firebase.storage();

export { auth, db, functions, storage, store };
