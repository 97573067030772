import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";

import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import LinearProgress from "@material-ui/core/LinearProgress";
//Hooks
import { makeStyles } from "@material-ui/core/styles";
//Icons
import UsbIcon from "@material-ui/icons/Usb";
//Colors
import green from "@material-ui/core/colors/green";

import { orderBy } from "lodash";
import { isEmpty } from "@firebase/util";

const useStyles = makeStyles(theme => ({
  root: {
    width: "100%",
    padding: 0,
    backgroundColor: theme.palette.background.paper
  },
  nested: {
    paddingLeft: theme.spacing(4)
  },
  isActive: {
    color: green[300]
  }
}));

const Devices = props => {
  const { data, handleNext, id } = props;
  const classes = useStyles();
  //Load data
  if (!props.data.length) {
    return <LinearProgress />;
  }

  const sortedData = orderBy(data, ["name"], ["asc"]);

  const getPointTypes = device => {
    if (!device.points) return "";

    const types = [];
    device.points.forEach(point => {
      if (!types.includes(point.dataObjectType)) {
        types.push(point.dataObjectType);
      }
    });
    return types;
  };

  const noEvents = points => {
    if (points.length) {
      let events;
      // If doesn't contain calendar, return false
      const hasCalendar = points.filter(p => p.dataObjectType === "calendar");
      if (!hasCalendar.length) return false;
      if (hasCalendar.length > 1) return false;

      // If contains calendar but nothing else
      events = points.filter(
        p => !Boolean(p.data.upcoming.length) && isEmpty(p.data.current)
      );
      return Boolean(events.length);
    } else {
      return false;
    }
  };

  return (
    <List
      component="nav"
      aria-labelledby="nested-list-subheader"
      className={classes.root}
    >
      {sortedData.map(device => {
        const pointTypes = getPointTypes(device);
        return (
          <ListItem
            button
            key={device.id}
            onClick={() => handleNext("device", device.id)}
            disabled={!Boolean(device.points.length) || noEvents(device.points)}
          >
            <ListItemIcon>
              <UsbIcon
                className={classNames({
                  [classes.isActive]: id === device.id
                })}
              />
            </ListItemIcon>
            <ListItemText
              primary={device.name}
              secondary={
                !Boolean(device.points.length)
                  ? `No Points`
                  : noEvents(device.points)
                  ? "No Calendar Events"
                  : device.points.length > 1
                  ? `${device.points.length} points: ${pointTypes.join(", ")}`
                  : `1 point: ${pointTypes.join(", ")}`
              }
              secondaryTypographyProps={{
                style: {
                  fontVariant: "all-petite-caps"
                }
              }}
            />
          </ListItem>
        );
      })}
    </List>
  );
};

Devices.propTypes = {
  data: PropTypes.array.isRequired
};

export default Devices;
