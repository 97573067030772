import React, { Component } from "react";

import { compose } from "redux";
import { connect } from "react-redux";

import Container from "@material-ui/core/Container";
import LinearProgress from "@material-ui/core/LinearProgress";
//Hooks
import { withStyles } from "@material-ui/core/styles";
//Icons
import ViewCarouselIcon from "@material-ui/icons/ViewCarousel"; //Building
import LayersIcon from "@material-ui/icons/Layers"; //Floor
import AspectRatioIcon from "@material-ui/icons/AspectRatio"; //Space
import UsbIcon from "@material-ui/icons/Usb"; //Device
//Colors
import green from "@material-ui/core/colors/green";
//Component
import Point from "../components/Point";

import { find } from "lodash";
import { Grid } from "@material-ui/core";
import { DATA_SAGA } from "../actions";

const buildTree = (data, classes) => {
  const { building, floor, space, device } = data;

  return [
    {
      label: building,
      icon: <ViewCarouselIcon className={classes.themeColor} />
    },
    {
      label: floor,
      icon: <LayersIcon className={classes.themeColor} />
    },
    {
      label: space,
      icon: <AspectRatioIcon className={classes.themeColor} />
    },
    {
      label: device,
      icon: <UsbIcon className={classes.themeColor} />
    }
  ];
};

class PointPage extends Component {
  constructor(props) {
    super(props);
    const {
      match: {
        params: { buildingId, floorId, spaceId, deviceId, id } = {}
      } = {}
    } = props;

    this.state = {
      isFetching: false,
      building: "",
      buildingId,
      floor: "",
      floorId,
      space: "",
      spaceId,
      device: "",
      deviceId,
      id,
      point: {}
    };
  }

  //Lifecycle
  componentWillMount() {
    if (!Boolean(this.props.data.buildings.length)) {
      this.props.getData(this.props.api, "buildings", null, "buildings");
      this.setState({ isFetching: true });
    } else {
      this.getPointData(this.state);
    }
  }

  // Hanlders
  getPointData = point => {
    const { data } = this.props;
    const building = find(data.buildings, ["id", point.buildingId]);
    const floor = find(building.floors, ["id", point.floorId]);
    const space = find(building.spaces, ["id", point.spaceId]);
    const device = find(building.devices, ["id", point.deviceId]);
    const pointData = find(device.points, ["id", point.id]);

    this.setState({
      building: building.name,
      floor: floor.name,
      space: space.name,
      device: device.name,
      point: pointData
    });
  };

  render() {
    const { classes, fetching } = this.props;
    const { isFetching, building, floor, space, device, point } = this.state;

    // Load data
    if (fetching || isFetching) {
      return <LinearProgress />;
    }

    // eslint-disable-next-line
    const tree = buildTree({ building, floor, space, device }, classes);

    return (
      <Container className={classes.root}>
        <Grid
          container
          style={{ padding: "1rem", borderBottom: "1px solid #ccc" }}
        >
          {tree &&
            tree.map((item, idx) => (
              <Grid
                key={`${item.label}_${idx}_`}
                item
                xs={6}
                style={{
                  justifyContent: idx % 2 === 1 ? "flex-start" : "flex-end",
                  display: "flex",
                  alignItems: "center",
                  padding: ".25rem 1rem"
                }}
              >
                {idx % 2 === 1 ? (
                  <>
                    <div style={{ padding: ".5rem" }}>{item.icon}</div>
                    <div>{item.label}</div>
                  </>
                ) : (
                  <>
                    <div
                      style={{
                        textAlign: idx === 0 ? "right" : "left",
                        padding: ".5rem"
                      }}
                    >
                      {item.label}
                    </div>
                    <div style={{ padding: ".5rem" }}>{item.icon}</div>
                  </>
                )}
              </Grid>
            ))}
        </Grid>
        {point && (
          <Grid item xs={12} style={{ padding: "2rem" }}>
            <Point {...point} hideData={true} />
          </Grid>
        )}
      </Container>
    );
  }
}

const styles = theme => ({
  root: {
    width: "100%",
    padding: 0,
    backgroundColor: theme.palette.background.paper
  },
  nested: {
    paddingLeft: theme.spacing(4)
  },
  themeColor: {
    color: theme.palette.primary.main
  },
  isActive: {
    color: green[300]
  }
});

export default compose(
  connect(
    state => ({
      api: state.user.api,
      data: state.data,
      fetching: state.data.fetching
    }),
    dispatch => ({
      getData: (api, filter, params, storeAs) =>
        dispatch({
          type: DATA_SAGA,
          payload: { api, filter, params, storeAs }
        })
    })
  ),
  withStyles(styles)
)(PointPage);
