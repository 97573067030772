import React, { Component } from "react";
import MaterialTable from "material-table";

import * as moment from "moment";
import { functions } from "../../firebase/firebase";

import Grid from "@material-ui/core/Grid";

import AddBox from "@material-ui/icons/AddBox";
import ArrowUpward from "@material-ui/icons/ArrowUpward";
import Check from "@material-ui/icons/Check";
import ChevronLeft from "@material-ui/icons/ChevronLeft";
import ChevronRight from "@material-ui/icons/ChevronRight";
import Clear from "@material-ui/icons/Clear";
import Notifications from "@material-ui/icons/Notifications";
import Edit from "@material-ui/icons/Edit";
import FilterList from "@material-ui/icons/FilterList";
import FirstPage from "@material-ui/icons/FirstPage";
import LastPage from "@material-ui/icons/LastPage";
import Remove from "@material-ui/icons/Remove";
import SaveAlt from "@material-ui/icons/SaveAlt";
import Search from "@material-ui/icons/Search";
import ViewColumn from "@material-ui/icons/ViewColumn";
import WarningIcon from "@material-ui/icons/Warning";

import { withStyles } from "@material-ui/core/styles";
import { Paper, LinearProgress } from "@material-ui/core";

class AlarmTable extends Component {
  constructor() {
    super();
    this.state = {
      updating: false
    };
  }

  render() {
    const {
      api,
      data,
      email,
      title,
      triggerFetch,
      handleDrawer,
      role
    } = this.props;
    const { updating } = this.state;

    if (!data) {
      return <span />;
    }

    let tableData = data;
    const acknowledge = functions.httpsCallable("acknowledgeAlarms");

    const handleAcknowledge = (evt, data) => {
      let alarmList = [];
      data.forEach(a => {
        alarmList.push(a.GUID);
      });
      // console.log(alarmList);
      this.setState({ updating: true });
      acknowledge({ alarms: alarmList, user: email, api, role }).then(res => {
        this.setState({ updating: false });
        triggerFetch();
      });
    };

    return (
      <Grid container>
        <Grid item xs={12}>
          {updating && <LinearProgress />}
          <MaterialTable
            data={
              tableData
                ? tableData
                : [
                    {
                      status: "",
                      device: "",
                      building: "",
                      floor: "",
                      space: ""
                    }
                  ]
            }
            options={{
              filtering: true,
              selection: true
            }}
            components={{
              Container: props => (
                <Paper elevation={0} square>
                  {props.children}
                </Paper>
              )
            }}
            columns={[
              {
                defaultSort: "asc",
                title: "Status",
                field: "state",
                render: rowData => {
                  return rowData.state === "ALARM" ? (
                    <div
                      style={{ display: "flex", justifyContent: "flex-start" }}
                    >
                      <span
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          alignItems: "center",
                          fontVariant: "all-petite-caps"
                        }}
                      >
                        <WarningIcon color="error" />
                        {rowData.state}
                      </span>
                    </div>
                  ) : (
                    rowData.state
                  );
                }
              },
              {
                title: "Time",
                field: "startTime",
                customFilterAndSearch: (term, rowData) =>
                  moment(rowData.startTime * 1000)
                    .format("MM/DD/YYYY")
                    .includes(term),

                render: rowData => (
                  <div style={{ display: "flex", flexDirection: "column" }}>
                    <span>
                      {moment(rowData.startTime * 1000).format("MM/DD/YYYY")}
                    </span>
                    <span>
                      {moment(rowData.startTime * 1000).format("h:mm A")}
                    </span>
                  </div>
                )
              },
              {
                title: "Device",
                field: "deviceName",
                render: rowData => (
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column"
                    }}
                  >
                    <span style={{ textDecoration: "underline" }}>
                      {rowData.deviceName}
                    </span>
                    <span>{rowData.pointName}</span>
                  </div>
                )
              },
              { title: "Space", field: "spaceName" },
              { title: "Floor", field: "floorName" },
              { title: "Message", field: "alarmMessage" }
            ]}
            title={title}
            onRowClick={(event, rowData, togglePanel) => {
              handleDrawer(rowData);
            }}
            icons={{
              Add: AddBox,
              Check: Check,
              Clear: Clear,
              Delete: Notifications,
              DetailPanel: ChevronRight,
              Edit: Edit,
              Export: SaveAlt,
              Filter: FilterList,
              FirstPage: FirstPage,
              LastPage: LastPage,
              NextPage: ChevronRight,
              PreviousPage: ChevronLeft,
              ResetSearch: Clear,
              Search: Search,
              SortArrow: ArrowUpward,
              ThirdStateCheck: Remove,
              ViewColumn: ViewColumn
            }}
            actions={[
              {
                tooltip: "Acknowledge Alarms",
                icon: () => <Notifications />,
                onClick: (evt, data) => handleAcknowledge(evt, data)
              }
            ]}
          />
        </Grid>
      </Grid>
    );
  }
}

const styles = theme => ({
  bar: {
    backgroundColor: theme.palette.primary.main
  }
});

export default withStyles(styles)(AlarmTable);
