import { auth } from "./firebase";

export const doCreateUserWithEmailAndPassword = ({ email, password }) => {
  try {
    return auth.createUserWithEmailAndPassword(email, password);
  } catch (err) {
    console.log(err);
  }
};

// Sign In
export const doSignInWithEmailAndPassword = ({ email, password }) =>
  auth.signInWithEmailAndPassword(email, password);

// Sign out
export const doLogOut = () => auth.signOut();

// Password Reset
export const doPasswordReset = email => auth.sendPasswordResetEmail(email);

// Password Change
export const doPasswordUpdate = password => auth.currentUser.updatePassword(password);
