import React, { Component } from "react";
import PropTypes from "prop-types";

import * as ACTIONS from "../../actions";

import { compose } from "redux";
import { connect } from "react-redux";

import { Slider, Rail, Handles, Tracks, Ticks } from "react-compound-slider";
import { withStyles } from "@material-ui/core/styles";
import { SliderRail, Handle, Track, Tick } from "./SliderComponents";

import { interpolateHcl } from "d3";
import * as scale from "d3-scale";

const style = () => ({
  root: {
    width: "100%"
  },
  slider: {
    position: "relative",
    width: "100%"
  }
});

class Slide extends Component {
  constructor(props) {
    super();
    this.state = {
      currentState: props.state.currentState,
      update: [props.value],
      needsUpdate: props.needsUpdate,
      values: [props.value]
    };
  }

  onUpdate = update => {
    if (!Number.isNaN(update[0]) || update[0] === undefined) return;

    this.setState({ update });
    if (update !== this.state.update) {
      this.props.setNeedsUpdate(true);
    }
  };

  onChange = values => {
    console.log(values);
    if (Number.isNaN(values[0]) || values[0] === undefined) return;

    this.setState({ updating: true });
    this.props.setNeedsUpdate(true);
    this.props.updateValue(
      this.props.api,
      "points",
      this.props.id,
      values[0],
      this.props.role
    );
    if (this.props.handleUpdate) {
      this.props.handleUpdate(this.props.id);
    }
  };

  getGradient = value => {
    const { gradient } = this.props;

    const scaleData = scale
      .scaleLinear()
      .domain(this.props.domain)
      .range([0, 1]);

    const scaledData = scaleData(value);
    let colors;
    if (!gradient) {
      colors = interpolateHcl("#3288bd", "#f43b47");
    } else {
      colors = interpolateHcl(gradient[0], gradient[1]);
    }
    const endColor = colors(scaledData);

    return {
      handle: endColor,
      track: `linear-gradient(45deg, ${colors(0)} 0%, ${endColor} 50%)`
    };
  };

  render() {
    const {
      props: { classes, domain, disableNumbers },
      state: { values }
    } = this;

    const colors = this.getGradient(this.props.value);

    return (
      <div className={classes.root} style={{ marginRight: "1rem" }}>
        <Slider
          mode={2}
          domain={domain}
          className={classes.slider}
          onUpdate={this.onUpdate}
          onChange={this.onChange}
          values={values}
        >
          <Rail>
            {({ getRailProps }) => <SliderRail getRailProps={getRailProps} />}
          </Rail>
          <Handles>
            {({ activeHandleID, handles, getHandleProps }) => (
              <div>
                {handles.map(handle => (
                  <Handle
                    key={handle.id}
                    handle={handle}
                    domain={domain}
                    activeHandleID={activeHandleID}
                    getHandleProps={getHandleProps}
                    color={colors.handle}
                  />
                ))}
              </div>
            )}
          </Handles>
          <Tracks right={false}>
            {({ tracks, getTrackProps }) => (
              <div>
                {tracks.map(({ id, source, target }) => (
                  <Track
                    key={id}
                    source={source}
                    target={target}
                    getTrackProps={getTrackProps}
                    color={colors.track}
                  />
                ))}
              </div>
            )}
          </Tracks>
          <Ticks count={5}>
            {({ ticks }) => (
              <div>
                {ticks.map(tick => (
                  <Tick
                    key={tick.id}
                    tick={tick}
                    count={ticks.length}
                    disableNumbers={disableNumbers}
                  />
                ))}
              </div>
            )}
          </Ticks>
        </Slider>
      </div>
    );
  }
}

Slide.propTypes = {
  classes: PropTypes.object.isRequired,
  api: PropTypes.string.isRequired,
  deviceId: PropTypes.string.isRequired,
  domain: PropTypes.array.isRequired,
  id: PropTypes.string.isRequired,
  state: PropTypes.object.isRequired,
  value: PropTypes.number.isRequired,
  updateAspect: PropTypes.func.isRequired,
  updateValue: PropTypes.func.isRequired,
  getData: PropTypes.func.isRequired
};

export default compose(
  withStyles(style),
  connect(
    state => ({
      api: state.user.api,
      role: state.user.role
    }),
    dispatch => ({
      updateAspect: (aspect, payload) =>
        dispatch({
          type: ACTIONS.UPDATE_DATA_ASPECT,
          aspect,
          payload
        }),
      updateValue: (api, route, id, value, role) =>
        dispatch({
          api,
          type: ACTIONS.POST_SAGA,
          route,
          id,
          value,
          role
        }),
      getData: (api, filter, params, storeAs, type) =>
        dispatch({
          type: ACTIONS.DATA_SAGA,
          payload: { api, filter, params, storeAs, type }
        })
    })
  )
)(Slide);
