import React from "react";
import PropTypes from "prop-types";
/* eslint-disable */

const SecurityDoor = props => {
  const { tag, status, state } = props;
  return <div>Security Door</div>;
};

SecurityDoor.propTypes = {
  tag: PropTypes.string.isRequired,
  status: PropTypes.string.isRequired,
  state: PropTypes.string.isRequired
};

export default SecurityDoor;
