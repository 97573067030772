import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";

// MUI
import Box from "@material-ui/core/Box";
import Container from "@material-ui/core/Container";
import IconButton from "@material-ui/core/IconButton";
import Step from "@material-ui/core/Step";
import StepIcon from "@material-ui/core/StepIcon";
import StepLabel from "@material-ui/core/StepLabel";
import Stepper from "@material-ui/core/Stepper";
import Typography from "@material-ui/core/Typography";
// Icons
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import ReplayIcon from "@material-ui/icons/Replay";
// Custom Stepper Icon
import ViewCarouselIcon from "@material-ui/icons/ViewCarousel"; //Building
import LayersIcon from "@material-ui/icons/Layers"; //Floor
import AspectRatioIcon from "@material-ui/icons/AspectRatio"; //Space
import UsbIcon from "@material-ui/icons/Usb"; //Device
//HOCs
import { makeStyles } from "@material-ui/core/styles";
//Colors
import { green } from "@material-ui/core/colors";
//Styles
const useStyles = makeStyles(theme => ({
  root: {
    [theme.breakpoints.down("xs")]: {
      padding: 0
    }
  },
  defaultBackground: {
    background: theme.palette.background.paper
  },
  buttonWrapper: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    padding: "2rem 1rem"
  },
  progressButton: {
    marginRight: theme.spacing(1)
  },
  replay: {
    border: `1px solid ${theme.palette.secondary.main}`
  },
  backbutton: {
    border: `1px solid ${theme.palette.primary.main}`
  },
  instructions: {
    textAlign: "center",
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
    userSelect: "none"
  },
  customIcon: {
    color: theme.palette.text.disabled
  },

  labelContainer: {
    "& $alternativeLabel": {
      marginTop: 0
    },
    root: {
      "& $completed": {
        color: green[300]
      }
    }
  },

  alternativeLabel: {
    "& $completed": {
      color: theme.palette.primary.main
    },
    root: {
      "& $completed": {
        color: theme.palette.primary.main
      }
    }
  },
  active: {
    color: theme.palette.text.primary
  },
  completed: {
    color: theme.palette.primary.main
  }
}));

const getSteps = (classes, activeStep, handleStep) => {
  return [
    {
      label: "Buildings",
      icon: (
        <ViewCarouselIcon
          onClick={activeStep > 0 ? () => handleStep(0) : () => {}}
          className={classNames(classes.customIcon, {
            [classes.active]: activeStep === 0,
            [classes.completed]: activeStep > 0
          })}
        />
      )
    },
    {
      label: "Floors",
      icon: (
        <LayersIcon
          onClick={activeStep > 1 ? () => handleStep(1) : () => {}}
          className={classNames(classes.customIcon, {
            [classes.active]: activeStep === 1,
            [classes.completed]: activeStep > 1
          })}
        />
      )
    },
    {
      label: "Spaces",
      icon: (
        <AspectRatioIcon
          onClick={activeStep > 2 ? () => handleStep(2) : () => {}}
          className={classNames(classes.customIcon, {
            [classes.active]: activeStep === 2,
            [classes.completed]: activeStep > 2
          })}
        />
      )
    },
    {
      label: "Devices",
      icon: (
        <UsbIcon
          onClick={activeStep > 3 ? () => handleStep(3) : () => {}}
          className={classNames(classes.customIcon, {
            [classes.active]: activeStep === 3,
            [classes.completed]: activeStep > 3
          })}
        />
      )
    }
  ];
};

const getStepContent = (stepIndex, value) => {
  switch (stepIndex) {
    case 0:
      return "Buildings";
    case 1:
      return "Select a floor";
    case 2:
      return "Select a space";
    case 3:
      return "Select a device";
    case 4:
      return value ? value : "Device Control";
    default:
      return "Uknown stepIndex";
  }
};

// Main
const AccessStepper = props => {
  const {
    pointData,
    activeStep,
    handleNext,
    handleBack,
    handleStep,
    handleReset,
    parentState
  } = props;
  const classes = useStyles();
  const steps = getSteps(classes, activeStep, handleStep);
  const { building, floor, space, device } = parentState;

  const getDeviceName = () => {
    return pointData ? `${pointData.name} Control` : "";
  };

  const isDisabled = () => {
    if (activeStep === steps.length - 1) return true;
    switch (activeStep) {
      case 0: {
        return Boolean(building === "");
      }

      case 1: {
        return Boolean(building === "" || floor === "");
      }

      case 2: {
        return Boolean(building === "" || floor === "" || space === "");
      }

      case 3: {
        return Boolean(
          building === "" || floor === "" || space === "" || device === ""
        );
      }

      default: {
        return false;
      }
    }
  };

  const renderChild = () => {
    let currentStep = steps[activeStep] ? steps[activeStep].label : "device";
    // Device Step
    if (currentStep === "device") {
      return props.children.filter(child => {
        return child.props.value === "Points";
      });
    }

    return props.children.filter(child => {
      return child.props.value === currentStep;
    });
  };

  // Render
  return (
    <Container maxWidth="md" className={classes.root}>
      <Stepper
        activeStep={activeStep}
        alternativeLabel
        className={classes.defaultBackground}
      >
        {steps.map((step, idx) => (
          <Step key={`${step.label}_${idx}`}>
            <StepLabel
              classes={{
                alternativeLabel: classes.alternativeLabel,
                labelContainer: classes.labelContainer,
                completed: classes.completed
              }}
              icon={
                <StepIcon
                  icon={step.icon}
                  classes={{
                    root: classes.customIcon,
                    active: classes.customIcon,
                    completed: classes.customIcon
                  }}
                />
              }
            >
              {step.label}
            </StepLabel>
          </Step>
        ))}
      </Stepper>
      <div>
        <div>
          <Container className={classes.buttonWrapper}>
            <IconButton
              disabled={activeStep === 0}
              onClick={handleBack}
              className={classNames(classes.progressButton, {
                [classes.backbutton]: activeStep !== 0
              })}
            >
              <ChevronLeftIcon />
            </IconButton>

            <Typography className={classes.instructions}>
              {getStepContent(activeStep, getDeviceName())}
            </Typography>

            {activeStep < steps.length ? (
              <IconButton
                style={{
                  border: !isDisabled() ? `1px solid ${green[300]}` : "none"
                }}
                disabled={isDisabled()}
                onClick={handleNext}
                className={classes.progressButton}
              >
                <ChevronRightIcon />
              </IconButton>
            ) : (
              <IconButton
                onClick={handleReset}
                className={classNames(classes.progressButton, {
                  [classes.replay]: !isDisabled()
                })}
              >
                <ReplayIcon />
              </IconButton>
            )}
          </Container>
        </div>
        <Box>{renderChild()}</Box>
      </div>
    </Container>
  );
};

AccessStepper.propTypes = {
  children: PropTypes.array.isRequired,
  handleBack: PropTypes.func.isRequired,
  handleNext: PropTypes.func.isRequired,
  parentState: PropTypes.object.isRequired
};

export default AccessStepper;
