import React, { useEffect } from "react";
import { connect } from "react-redux";
import { compose } from "redux";
import * as ACTIONS from "../../actions";

import { countBy } from "lodash";

// MUI
import AppBar from "@material-ui/core/AppBar";
import Badge from "@material-ui/core/Badge";
import CircularProgress from "@material-ui/core/CircularProgress";
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
// HOCs
import { withStyles } from "@material-ui/core/styles";
// Icons
import ErrorIcon from "@material-ui/icons/Error";
import NotificationsIcon from "@material-ui/icons/Notifications";
import WarningIcon from "@material-ui/icons/Warning";

//Components
import AlarmTable from "./AlarmTable";
import AlarmDrawer from "./AlarmDrawer";
import { push } from "connected-react-router";
import { IconButton } from "@material-ui/core";

const BuildingAlarms = props => {
  const {
    classes,
    alarms,
    building,
    email,
    api,
    updating,
    reroute,
    role
  } = props;
  const [open, setOpen] = React.useState(false);
  const [drawerData, setDrawerData] = React.useState({});
  const [filter, setFilter] = React.useState("");

  const triggerFetch = () => {
    const {
      building: { id }
    } = props;
    console.log(alarms, updating, id);

    props.getAlarms(
      props.api,
      `alarm-history/building/${id}`,
      null,
      id,
      "updating",
      "alarms"
    );
  };

  useEffect(() => {
    const {
      building: { id }
    } = props;

    if (!alarms[id]) {
      triggerFetch();
    }

    /* eslint-disable-next-line */
  }, [alarms, filter]);

  if (updating || !alarms[building.id]) {
    return (
      <Container
        maxWidth="md"
        style={{
          position: "absolute",
          display: "flex",
          top: "50%",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center"
        }}
      >
        <CircularProgress variant="indeterminate" color="primary" />
      </Container>
    );
  }

  const alarmAcknowledged = countBy(
    alarms[building.id].alarms,
    o => o.acknowledged
  );
  const alarmState = countBy(alarms[building.id].alarms, o => o.state);

  const filteredAlarms = filter => {
    switch (filter) {
      case "": {
        return alarms[building.id].alarms;
      }

      case "alarm": {
        let alarmCopy = alarms[building.id];
        return alarmCopy.alarms.filter(a => {
          return a.state === "ALARM";
        });
      }

      case "outstanding": {
        let alarmCopy = alarms[building.id];
        return alarmCopy.alarms.filter(a => {
          return !a.acknowledged;
        });
      }

      case "acknowledged": {
        let alarmCopy = alarms[building.id];
        return alarmCopy.alarms.filter(a => {
          return a.acknowledged;
        });
      }

      default: {
        return alarms[building.id].alarms;
      }
    }
  };

  const handleFilter = newFilter => {
    setFilter(filter === newFilter ? "" : newFilter);
  };

  const handleDrawer = rowData => {
    setDrawerData(rowData);
    setOpen(true);
  };

  return (
    <Container align="center" maxWidth="md">
      <AlarmDrawer
        open={open}
        setOpen={setOpen}
        drawerData={drawerData}
        reroute={reroute}
        api={api}
        getAlarms={props.getAlarms}
      />
      <Grid container>
        <div className={classes.grow}>
          <div className={classes.key}>
            <div className={classes.keyChild}>
              <WarningIcon />
              Alarm
            </div>
            <div className={classes.keyChild}>
              <ErrorIcon /> Outstanding
            </div>
            <div className={classes.keyChild}>
              <NotificationsIcon /> Acknowledged
            </div>
          </div>
          <AppBar position="static" style={{ boxShadow: "none" }}>
            <Toolbar>
              <Typography className={classes.title} variant="h6" noWrap>
                {building.name}
              </Typography>
              <div className={classes.grow} />
              <div className={classes.sectionDesktop}>
                <IconButton
                  style={{
                    marginRight: "1rem",
                    color: filter === "alarm" ? "white" : "#333"
                  }}
                  onClick={() => handleFilter("alarm")}
                >
                  <Badge badgeContent={alarmState.ALARM} color="error">
                    <WarningIcon />
                  </Badge>
                </IconButton>
                <IconButton
                  style={{
                    marginRight: "1rem",
                    color: filter === "outstanding" ? "white" : "#333"
                  }}
                  onClick={() => handleFilter("outstanding")}
                >
                  <Badge
                    badgeContent={alarmAcknowledged.false}
                    color="secondary"
                  >
                    <ErrorIcon />
                  </Badge>
                </IconButton>
                <IconButton
                  style={{
                    marginRight: "1rem",
                    color: filter === "acknowledged" ? "white" : "#333"
                  }}
                  onClick={() => handleFilter("acknowledged")}
                >
                  <Badge
                    badgeContent={alarmAcknowledged.true}
                    color="secondary"
                  >
                    <NotificationsIcon />
                  </Badge>
                </IconButton>
              </div>
            </Toolbar>
          </AppBar>
        </div>
        {/* Table */}
        <div className={classes.table}>
          <AlarmTable
            filter={filter}
            title="alarms"
            data={filteredAlarms(filter)}
            reroute={reroute}
            api={api}
            email={email}
            triggerFetch={triggerFetch}
            handleDrawer={handleDrawer}
            role={role}
          />
        </div>
      </Grid>
    </Container>
  );
};

const styles = theme => ({
  grow: {
    flexGrow: 1
  },
  menuButton: {
    marginRight: theme.spacing(2)
  },
  title: {
    display: "block"
  },
  sectionDesktop: {
    display: "none",
    [theme.breakpoints.up("sm")]: {
      display: "flex"
    }
  },
  sectionMobile: {
    display: "flex"
  },
  key: {
    display: "flex",
    justifyContent: "flex-end",
    color: "#ccc",
    padding: ".5rem"
  },
  keyChild: {
    display: "flex",
    alignItems: "center",
    marginRight: "1rem"
  },
  table: {
    width: "100%"
  }
});

export default compose(
  connect(
    state => ({
      api: state.user.api,
      alarms: state.data.alarms,
      buildings: state.data.buildings,
      email: state.user.profile.email,
      updating: state.data.updating,
      role: state.user.role
    }),
    dispatch => ({
      reroute: route => dispatch(push(route)),
      getAlarms: (api, filter, params, storeAs, type, variant) =>
        dispatch({
          type: ACTIONS.DATA_SAGA,
          payload: { api, filter, params, storeAs, type, variant }
        })
    })
  ),
  withStyles(styles)
)(BuildingAlarms);
