import * as ACTIONS from "../actions";

const INITIAL_STATE = {
  selectedSite: "",
  alarms: {},
  data: {},
  clients: {},
  buildings: [],
  calendars: [],
  fetching: false,
  updating: false,
  errors: ""
};

function dataReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    //Generic Updates
    case ACTIONS.UPDATE_DATA_ASPECT: {
      return { ...state, [`${action.aspect}`]: action.payload };
    }

    case ACTIONS.UPDATE_DATA_REDUCER: {
      return { ...state, ...action.payload };
    }

    case ACTIONS.UPDATE_ALARMS: {
      return {
        ...state,
        alarms: { ...state.alarms, [action.building]: action.payload }
      };
    }

    default:
      return state;
  }
}

export default dataReducer;
