import React from "react";
import PropTypes from "prop-types";
import { isEmpty } from "lodash";

// MUI
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";

//HOCS
import { withStyles } from "@material-ui/core/styles";

//Components
import CalendarPoint from "../Point/CalendarPoint";

const CalendarContainer = ({ classes, data, color }) => {
  const { building, calendar, device } = data;

  return calendar.map((cal, idx) => {
    if (isEmpty(cal.data.current) && !Boolean(cal.data.upcoming.length)) {
      return (
        <span key={`${idx}__${cal.id}`} style={{ position: "absolute" }} />
      );
    } else {
      return (
        <Grid
          item
          xs={12}
          md={6}
          lg={4}
          className={classes.calendarContainer}
          key={cal.id}
        >
          <Container maxWidth="sm" style={{ padding: 0 }}>
            <Typography variant="h6" align="center">
              {building}
            </Typography>
            <Typography
              variant="h6"
              align="center"
              style={{ fontSize: "1rem" }}
            >
              {device}
            </Typography>
            <span className={classes.border} />
            <CalendarPoint data={cal.data} name={cal.name} />
          </Container>
        </Grid>
      );
    }
  });
};

CalendarContainer.propTypes = {
  classes: PropTypes.object.isRequired,
  calendar: PropTypes.shape({
    building: PropTypes.string.isRequired,
    calendar: PropTypes.array.isRequired,
    device: PropTypes.string.isRequired
  })
};

const styles = theme => ({
  calendarContainer: {
    display: "flex",
    flexDirection: "column",
    flexWrap: "wrap"
  },
  container: {
    padding: 0
  },
  border: {
    display: "flex",
    margin: "0 auto",
    padding: ".5rem",
    height: 1,
    width: "50%",
    borderBottom: `2px solid ${theme.palette.secondary.main}`
  }
});

export default withStyles(styles)(CalendarContainer);
