import React from "react";
import PropTypes from "prop-types";
/* eslint-disable */

const SecurityLog = props => {
  const { type, status, timeStamp } = props;
  return <div>Security Log</div>;
};

SecurityLog.propTypes = {
  type: PropTypes.string.isRequired,
  status: PropTypes.string.isRequired,
  timeStamp: PropTypes.string.isRequired
};

export default SecurityLog;
