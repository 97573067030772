import { call, put, takeEvery } from "redux-saga/effects";

//Actions
import * as ACTIONS from "../actions";

//Route
//Firebase Auth
import { db } from "./../firebase";

//Saga Handler
function* handleUser(action) {
  const { filter, payload } = action;
  const { uid } = payload;

  if (filter === "GET_USER") {
    //Trigger sign in
    let result, error;
    try {
      yield put({
        type: ACTIONS.UPDATE_USER_ASPECT,
        aspect: "fetchingUser",
        payload: true
      });

      result = yield call(db.getUser, uid);

      if (result) {
        yield put({
          type: ACTIONS.UPDATE_USER_REDUCER,
          payload: result
        });
        yield put({
          type: ACTIONS.UPDATE_USER_ASPECT,
          aspect: "didFetchUser",
          payload: true
        });
        yield put({
          type: ACTIONS.UPDATE_USER_ASPECT,
          aspect: "fetchingUser",
          payload: false
        });
      }
    } catch (err) {
      console.log(err);
      error = err;

      yield put({
        type: ACTIONS.UPDATE_SESSION_ASPECT,
        aspect: "error",
        payload: error.message
      });
    }

    if (result && result.aspect) {
      if (result.aspect === "error") {
        yield put(result);
      }
    }
  }
}

export default function* userSaga() {
  yield takeEvery(ACTIONS.USER_SAGA, handleUser);
}
