import React, { Component } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import { compose } from "redux";
import { connect } from "react-redux";

//MUI
import Button from "@material-ui/core/Button";
import Card from "@material-ui/core/Card";
import CardHeader from "@material-ui/core/CardHeader";
import CardContent from "@material-ui/core/CardContent";
import Grid from "@material-ui/core/Grid";
import InputAdornment from "@material-ui/core/InputAdornment";
import Slide from "@material-ui/core/Slide";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";

import { withStyles } from "@material-ui/core/styles";

//Icons
import ArrowRightAltIcon from "@material-ui/icons/ArrowRightAlt";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";

//Mask
import {
  SIGN_IN_EMAIL,
  AUTH_SAGA,
  UPDATE_SESSION_ASPECT,
  SIGN_UP_EMAIL
} from "../../actions";
import { push } from "connected-react-router";
import { Container } from "@material-ui/core";
import Dashboard from "../../pages/Dashboard";

class LoginCard extends Component {
  state = {
    isFlipped: true,
    showPassword: false,
    email: "",
    firstName: "",
    lastName: "",
    api: "",
    password: "",
    loginEmail: "",
    loginPassword: ""
  };

  componentWillUnmount() {
    const { clearErrors, error } = this.props;
    return error ? clearErrors() : null;
  }

  render() {
    const { classes, didFetchUser, error } = this.props;
    const { isFlipped } = this.state;

    if (didFetchUser) {
      return (
        <Container
          maxWidth="md"
          style={{ justifyContent: "center", position: "relative" }}
        >
          <Dashboard />
        </Container>
      );
    }

    return (
      <Container
        maxWidth="md"
        style={{ justifyContent: "flex-end", position: "relative" }}
      >
        <Card className={classes.card}>
          {error ? (
            <CardHeader
              className={classNames(classes.cardHeader)}
              title={isFlipped ? "Email in Use" : "SIGN IN"}
              subheader={isFlipped ? error : error}
              subheaderTypographyProps={{
                color: "error"
              }}
            />
          ) : (
            <CardHeader
              className={classNames(classes.cardHeader)}
              title={isFlipped ? "SIGN UP" : "SIGN IN"}
              subheader={
                isFlipped
                  ? "If you were given an API key, enter it below."
                  : "Enter your email and password to login"
              }
            />
          )}

          {/* Default */}
          <CardContent className={classNames(classes.cardContent)}>
            <Slide
              direction="right"
              in={isFlipped}
              mountOnEnter
              unmountOnExit
              className={classes.slide}
            >
              <Grid container>
                <Grid item xs={12}>
                  <TextField
                    className={classNames(classes.textField, classes.margin)}
                    required
                    variant="outlined"
                    autoFocus
                    fullWidth
                    value={this.state.email}
                    label={"Email"}
                    // eslint-disable-next-line
                    InputProps={{
                      value: this.state.email,
                      onChange: this.handleChange("email"),
                      classes: {
                        root: classes.cssOutlinedInput,
                        focused: classes.cssFocused,
                        notchedOutline: classes.notchedOutline
                      }
                    }}
                    InputLabelProps={{
                      classes: {
                        animated: classes.labelProps,
                        root: classes.cssLabel,
                        focused: classes.cssFocused
                      }
                    }}
                  />
                </Grid>
                <Grid item xs={4}>
                  <TextField
                    className={classes.textField}
                    onChange={this.handleChange("firstName")}
                    variant="outlined"
                    value={this.state.firstName}
                    label={"First"}
                    InputLabelProps={{
                      classes: {
                        root: classes.cssLabel,
                        focused: classes.cssFocused
                      }
                    }}
                    InputProps={{
                      classes: {
                        root: classes.cssOutlinedInput,
                        focused: classes.cssFocused,
                        notchedOutline: classes.notchedOutline
                      }
                    }}
                  />
                </Grid>
                <Grid item xs={8}>
                  <TextField
                    className={classNames(
                      classes.textField,
                      classes.noLeftBorder
                    )}
                    onChange={this.handleChange("lastName")}
                    variant="outlined"
                    value={this.state.lastName}
                    label={"Last"}
                    InputLabelProps={{
                      classes: {
                        root: classes.cssLabel,
                        focused: classes.cssFocused
                      }
                    }}
                    InputProps={{
                      classes: {
                        root: classes.cssOutlinedInput,
                        focused: classes.cssFocused,
                        notchedOutline: classes.notchedOutline
                      }
                    }}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    className={classNames(classes.textField)}
                    variant="outlined"
                    fullWidth
                    value={this.state.api}
                    label={"API Key"}
                    // eslint-disable-next-line
                    InputProps={{
                      value: this.state.api,
                      onChange: this.handleChange("api"),
                      classes: {
                        root: classes.cssOutlinedInput,
                        focused: classes.cssFocused,
                        notchedOutline: classes.notchedOutline
                      }
                    }}
                    InputLabelProps={{
                      classes: {
                        animated: classes.labelProps,
                        root: classes.cssLabel,
                        focused: classes.cssFocused
                      }
                    }}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    className={classNames(classes.textField, classes.margin)}
                    required
                    variant="outlined"
                    label={"Password"}
                    fullWidth
                    type={this.state.showPassword ? "text" : "password"}
                    value={this.state.password}
                    onChange={this.handleChange("password")}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          {this.state.showPassword ? (
                            <VisibilityOff
                              onClick={this.handleClickShowPassword}
                            />
                          ) : (
                            <Visibility
                              onClick={this.handleClickShowPassword}
                            />
                          )}
                        </InputAdornment>
                      ),
                      value: this.state.password,
                      onChange: this.handleChange("password"),
                      classes: {
                        root: classes.cssOutlinedInput,
                        focused: classes.cssFocused,
                        notchedOutline: classes.notchedOutline
                      }
                    }}
                    InputLabelProps={{
                      classes: {
                        animated: classes.labelProps,
                        root: classes.cssLabel,
                        focused: classes.cssFocused
                      }
                    }}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Button
                    fullWidth
                    square="true"
                    variant="contained"
                    color="primary"
                    className={classes.button}
                    onClick={this.handleSignUp}
                    disabled={
                      this.state.email === "" || this.state.password === ""
                    }
                  >
                    sign up
                  </Button>
                </Grid>
              </Grid>
            </Slide>
            <Slide
              direction="left"
              in={!isFlipped}
              mountOnEnter
              unmountOnExit
              className={classNames(classes.slide)}
            >
              <Grid container>
                <form action="/" onSubmit={this.handleSignIn}>
                  <Grid item xs={12}>
                    <TextField
                      className={classNames(classes.textField, classes.margin)}
                      required
                      autoFocus
                      variant="outlined"
                      fullWidth
                      value={this.state.loginEmail}
                      label={"Email"}
                      // eslint-disable-next-line
                      InputProps={{
                        value: this.state.loginEmail,
                        onChange: this.handleChange("loginEmail"),
                        classes: {
                          root: classes.cssOutlinedInput,
                          focused: classes.cssFocused,
                          notchedOutline: classes.notchedOutline
                        }
                      }}
                      InputLabelProps={{
                        classes: {
                          animated: classes.labelProps,
                          root: classes.cssLabel,
                          focused: classes.cssFocused
                        }
                      }}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      className={classNames(classes.textField, classes.margin)}
                      required
                      variant="outlined"
                      label={"Password"}
                      fullWidth
                      type={this.state.showLoginPassword ? "text" : "password"}
                      value={this.state.loginPassword}
                      onChange={this.handleChange("loginPassword")}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            {this.state.showLoginPassword ? (
                              <VisibilityOff
                                onClick={this.handleLoginClickShowPassword}
                              />
                            ) : (
                              <Visibility
                                onClick={this.handleLoginClickShowPassword}
                              />
                            )}
                          </InputAdornment>
                        ),
                        value: this.state.loginPassword,
                        onChange: this.handleChange("loginPassword"),
                        classes: {
                          root: classes.cssOutlinedInput,
                          focused: classes.cssFocused,
                          notchedOutline: classes.notchedOutline
                        }
                      }}
                      InputLabelProps={{
                        classes: {
                          animated: classes.labelProps,
                          root: classes.cssLabel,
                          focused: classes.cssFocused
                        }
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} style={{ paddingTop: "1em" }}>
                    <Button
                      fullWidth
                      square="true"
                      variant="contained"
                      color="primary"
                      className={classes.button}
                      onClick={this.handleSignIn}
                      disabled={
                        this.state.loginEmail === "" ||
                        this.state.loginPassword === ""
                      }
                    >
                      sign in
                    </Button>
                  </Grid>
                </form>
              </Grid>
            </Slide>
          </CardContent>

          <Button
            onClick={() => this.handleFlip()}
            fullWidth
            className={classes.flipButton}
          >
            <Typography>{isFlipped ? "sign in" : "sign up"}</Typography>
            <ArrowRightAltIcon
              className={classNames(classes.icon, {
                [classes.rotated]: !isFlipped
              })}
            />
          </Button>
        </Card>
      </Container>
    );
  }

  handleChange = name => event => {
    this.setState({
      [name]: event.target.value
    });
  };

  handleClickShowPassword = () => {
    this.setState(state => ({ showPassword: !state.showPassword }));
  };

  handleLoginClickShowPassword = () => {
    this.setState(state => ({ showLoginPassword: !state.showLoginPassword }));
  };

  handleFlip = () => {
    const { clearErrors } = this.props;
    this.setState(state => ({ isFlipped: !state.isFlipped }));
    clearErrors();
  };

  handleSignIn = event => {
    event.preventDefault();
    const { dispatch, signInEmail } = this.props;
    const { loginEmail, loginPassword } = this.state;
    signInEmail({ email: loginEmail, password: loginPassword }, dispatch);
  };

  handleSignUp = () => {
    const { dispatch, signUpEmail } = this.props;
    const { email, password, firstName, lastName, api } = this.state;
    signUpEmail({ email, password, firstName, lastName, api }, dispatch);
  };
}

const mapStateToProps = state => ({
  authUser: state.session.authUser,
  didFetchUser: state.user.didFetchUser,
  error: state.session.error
});

const mapDispatchToProps = dispatch => ({
  dispatch,
  reroute: route => dispatch(push(route)),
  clearErrors: () => {
    dispatch({
      type: UPDATE_SESSION_ASPECT,
      aspect: "error",
      payload: ""
    });
  },
  signInEmail: payload =>
    dispatch({
      type: AUTH_SAGA,
      filter: SIGN_IN_EMAIL,
      payload
    }),
  signUpEmail: (payload, dispatch) =>
    dispatch({
      type: AUTH_SAGA,
      filter: SIGN_UP_EMAIL,
      payload,
      dispatch
    })
});

const styles = theme => ({
  root: {
    backgroundColor: "transparent"
  },
  slide: {
    height: 150,
    position: "absolute",
    left: 0,
    top: 0,
    width: "100%",
    userSelect: "none"
  },
  error: {
    color: "red"
  },
  focusHighlight: {
    backgroundColor: "transparent"
  },
  cssLabel: {
    "&$cssFocused": {
      color: theme.palette.secondary.main,
      backgroundColor: "white"
    }
  },
  cssFocused: {},
  cssUnderline: {
    "&:after": {
      borderBottomColor: theme.palette.secondary.main,
      backgroundColor: "white"
    }
  },
  cssOutlinedInput: {
    "&$cssFocused $notchedOutline": {
      borderColor: theme.palette.secondary.main
    }
  },
  notchedOutline: {},
  card: {
    justifyContent: "center",
    height: 550,
    maxWidth: 345,
    padding: "2em",
    margin: "0 auto",
    position: "relative",
    borderRadius: 0
  },
  cardHeader: {
    height: "100px"
  },
  cardContent: {
    height: 400,
    position: "relative"
  },
  slideContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    padding: "1em"
  },
  textField: {
    fontSize: "1em"
  },
  noLeftBorder: {
    borderLeft: "unset"
  },
  margin: {
    margin: "1em 0"
  },
  buttonContainer: {
    display: "flex",
    justifyContent: "space-between",
    margin: "1em"
  },
  button: {
    color: "white",
    borderRadius: 0,
    padding: "1em"
  },
  bottomButton: {
    fontSize: "1em",
    letterSpacing: ".2em",
    borderRadius: 0,
    color: "white"
  },
  flipButton: {
    justifyContent: "space-between",
    padding: "1em 2em",
    borderRadius: 0,
    position: "absolute",
    bottom: 0,
    left: 0
  },
  icon: {
    transition: "transform .1s ease-in-out"
  },
  rotated: {
    transform: "rotate(180deg)"
  },
  rotatedY: {
    transform: "rotateY(180deg)"
  }
});

LoginCard.propTypes = {
  classes: PropTypes.object.isRequired
};

export default compose(
  withStyles(styles),
  connect(
    mapStateToProps,
    mapDispatchToProps
  )
)(LoginCard);
