import React, { Component } from "react";
import MaterialTable from "material-table";

import Grid from "@material-ui/core/Grid";

import Snackbar from "@material-ui/core/Snackbar";
import Fade from "@material-ui/core/Fade";

import AddBox from "@material-ui/icons/AddBox";
import ArrowUpward from "@material-ui/icons/ArrowUpward";
import Check from "@material-ui/icons/Check";
import ChevronLeft from "@material-ui/icons/ChevronLeft";
import ChevronRight from "@material-ui/icons/ChevronRight";
import Clear from "@material-ui/icons/Clear";
import DeleteOutline from "@material-ui/icons/DeleteOutline";
import Edit from "@material-ui/icons/Edit";
import FilterList from "@material-ui/icons/FilterList";
import FirstPage from "@material-ui/icons/FirstPage";
import LastPage from "@material-ui/icons/LastPage";
import Remove from "@material-ui/icons/Remove";
import SaveAlt from "@material-ui/icons/SaveAlt";
import Search from "@material-ui/icons/Search";
import ViewColumn from "@material-ui/icons/ViewColumn";

import { find, isEmpty, uniq } from "lodash";

import { withStyles } from "@material-ui/core/styles";

class LightsTable extends Component {
  constructor() {
    super();
    this.state = {
      updatedDevices: [],
      open: false,
      snackMessage: "",
      Transition: Fade
    };
  }

  shouldComponentUpdate(nextProps, nextState) {
    const prevUpdate = find(this.props.data, ob =>
      this.state.updatedDevices.includes(ob.data.id)
    );
    const nextUpdate = find(nextProps.data, ob =>
      this.state.updatedDevices.includes(ob.data.id)
    );

    if (!isEmpty(prevUpdate) && !isEmpty(nextUpdate)) {
      if (
        Number(prevUpdate.data.data.value).toFixed(0) !==
        Number(nextUpdate.data.data.value).toFixed(0)
      ) {
        this.setState({
          updatedDevices: this.state.updatedDevices.filter(
            e => e !== nextUpdate.data.id
          ),
          snackMessage: `Update Successful: ${nextUpdate.data.name} set to ${
            nextUpdate.data.data.value ? "On" : "Off"
          }`,
          open: true
        });
        return true;
      }
    }

    if (this.state.open !== nextState.open) {
      return true;
    }

    if (this.props.data.length !== nextProps.data.length) {
      return true;
    }

    return false;
  }

  // handleClose() {
  //   this.setState({
  //     open: false
  //   });
  // }

  handleUpdate = id => {
    const updatingDevices = [...this.state.updatedDevices, id];
    this.setState({ updatedDevices: uniq(updatingDevices) });
  };

  render() {
    const { classes, data, title, reroute } = this.props;
    const { open, snackMessage } = this.state;

    return (
      <Grid container>
        <Snackbar
          ContentProps={{
            "aria-describedby": "message-id",
            className: classes.bar,
            classes: {
              root: classes.bar
            }
          }}
          open={open}
          onClose={() => this.setState({ open: false })}
          TransitionComponent={this.state.Transition}
          message={<span id="message-id">{snackMessage}</span>}
        />
        <Grid item xs={12}>
          <MaterialTable
            columns={[
              {
                title: "Status",
                field: "icon",
                render: rowData => rowData.icon
              },
              {
                title: "Point",
                field: "point"
              },
              { title: "Device", field: "device" },
              { title: "Building", field: "building" },
              { title: "Floor", field: "floor" },
              { title: "Space", field: "space" }
            ]}
            data={
              data
                ? data
                : [
                    {
                      status: "",
                      device: "",
                      building: "",
                      floor: "",
                      space: ""
                    }
                  ]
            }
            title={title}
            onRowClick={(event, rowData, togglePanel) => {
              // togglePanel();
              let { buildingId, floorId, spaceId, deviceId, id } = rowData.data;
              reroute(
                `/point/${buildingId}/${floorId}/${spaceId}/${deviceId}/${id}`
              );
            }}
            icons={{
              Add: AddBox,
              Check: Check,
              Clear: Clear,
              Delete: DeleteOutline,
              DetailPanel: ChevronRight,
              Edit: Edit,
              Export: SaveAlt,
              Filter: FilterList,
              FirstPage: FirstPage,
              LastPage: LastPage,
              NextPage: ChevronRight,
              PreviousPage: ChevronLeft,
              ResetSearch: Clear,
              Search: Search,
              SortArrow: ArrowUpward,
              ThirdStateCheck: Remove,
              ViewColumn: ViewColumn
            }}
          />
        </Grid>
      </Grid>
    );
  }
}

const styles = theme => ({
  bar: {
    backgroundColor: theme.palette.primary.main
  }
});

export default withStyles(styles)(LightsTable);
