import React, { useState, useEffect } from "react";
import { compose } from "redux";
import { connect } from "react-redux";

import Avatar from "@material-ui/core/Avatar";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import ButtonGroup from "@material-ui/core/ButtonGroup";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import Grow from "@material-ui/core/Grow";
import Paper from "@material-ui/core/Paper";
import Popper from "@material-ui/core/Popper";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import MenuItem from "@material-ui/core/MenuItem";
import MenuList from "@material-ui/core/MenuList";

//Icons
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import AddIcon from "@material-ui/icons/Add";
import PersonIcon from "@material-ui/icons/Person";
import PersonOutlineIcon from "@material-ui/icons/PersonOutline";

//HOCS
import { withStyles } from "@material-ui/core/styles";
import { deepOrange, green, cyan } from "@material-ui/core/colors";

import ScheduleDialog from "../Schedule/ScheduleDialog";
import { orderBy } from "lodash";

import * as moment from "moment";

const options = [
  "MONDAY",
  "TUESDAY",
  "WEDNESDAY",
  "THURSDAY",
  "FRIDAY",
  "SATURDAY",
  "SUNDAY"
];

const Schedule = props => {
  const { classes, data, api, role, email, id, deviceId, getData } = props;
  const anchorRef = React.useRef(null);
  const [open, setOpen] = React.useState(false);
  const [selectedIndex, setSelectedIndex] = React.useState(0);
  const [day, setDay] = React.useState(options[selectedIndex]);
  const [openScheduleDialog, setOpenScheduleDialog] = React.useState("");

  const [ready, setReady] = useState(false);
  const [wsClient, setWsClient] = useState();
  const [needsUpdate, setNeedsUpdate] = useState(false);
  const [parentData, setParentData] = useState(data);

  function handleClick() {
    // alert(`You clicked ${options[selectedIndex]}`);
  }

  function handleMenuItemClick(event, index) {
    setSelectedIndex(index);
    setDay(options[index]);
    setOpen(false);
  }

  function handleToggle() {
    setOpen(prevOpen => !prevOpen);
  }

  function handleClose(event) {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }

    setOpen(false);
  }

  const handleScheduleDialog = id => {
    setOpenScheduleDialog(id);
  };

  // Set up socket
  if (!wsClient && !ready) {
    const client = new WebSocket(
      "wss://ptxrcj27wa.execute-api.us-east-1.amazonaws.com/v1"
    );

    client.onopen = evt => {
      setReady(true);
      setWsClient(client);
    };

    client.onclose = evt => {
      setWsClient();
    };

    client.onerror = evt => {
      setWsClient();
      client.close();
    };
  }

  if (ready && needsUpdate && wsClient) {
    // Socket
    wsClient.send(
      JSON.stringify({
        action: "subscribe",
        devices: [deviceId]
      })
    );

    wsClient.onmessage = evt => {
      if (evt.data) {
        const data = JSON.parse(evt.data);
        if (data.data) {
          if (data.id === id) {
            getData(api, "buildings", null, "buildings");
          }
        }
      }
    };
  }

  useEffect(() => {
    setParentData(data);
    setNeedsUpdate(needsUpdate);
  }, [data, needsUpdate]);

  // console.log("Parent: ", parentData);
  // console.log(wsClient);
  // console.log(needsUpdate);
  // console.log(ready);

  return (
    <Grid container>
      {/* Add Dialog */}
      <Grid
        item
        xs={12}
        style={{
          display: "flex",
          justifyContent: "center",
          flexDirection: "column",
          alignItems: "center"
        }}
      >
        <ScheduleDialog
          open={openScheduleDialog === id}
          setOpen={setOpenScheduleDialog}
          id={id}
          api={api}
          role={role}
          user={email}
          scheduleData={parentData}
          setNeedsUpdate={setNeedsUpdate}
          setParentData={setParentData}
        />
      </Grid>
      <Grid item xs={12} align="center">
        <ButtonGroup
          variant="contained"
          color="primary"
          ref={anchorRef}
          aria-label="Split button"
        >
          <Button onClick={handleClick}>{options[selectedIndex]}</Button>
          <Button
            color="primary"
            variant="contained"
            size="small"
            aria-owns={open ? "menu-list-grow" : undefined}
            aria-haspopup="true"
            onClick={handleToggle}
          >
            <ArrowDropDownIcon />
          </Button>
          <Button
            variant="contained"
            size="small"
            aria-owns={open ? "menu-list-grow" : undefined}
            aria-haspopup="true"
            onClick={() => handleScheduleDialog(id)}
            style={{ background: green[500], color: "white" }}
          >
            <AddIcon />
          </Button>
        </ButtonGroup>
        <Popper
          style={{ background: "white", zIndex: 99999 }}
          open={open}
          anchorEl={anchorRef.current}
          transition
          disablePortal
        >
          {({ TransitionProps, placement }) => (
            <Grow
              {...TransitionProps}
              style={{
                transformOrigin:
                  placement === "bottom" ? "center top" : "center bottom"
              }}
            >
              <Paper id="menu-list-grow">
                <ClickAwayListener onClickAway={handleClose}>
                  <MenuList style={{ zIndex: 99999, background: "white" }}>
                    {options.map((option, index) => (
                      <MenuItem
                        key={option}
                        selected={index === selectedIndex}
                        onClick={event => handleMenuItemClick(event, index)}
                      >
                        {option}
                      </MenuItem>
                    ))}
                  </MenuList>
                </ClickAwayListener>
              </Paper>
            </Grow>
          )}
        </Popper>
      </Grid>
      <Grid
        item
        xs={12}
        align="center"
        style={{ marginBottom: "1rem", borderBottom: "1px solid #ccc" }}
      >
        <List className={classes.root}>
          <ListItem>
            <ListItemText primary="Current Schedule" />
            <ListItemAvatar>
              <Avatar
                classes={{
                  root: classes.currentRoot
                }}
              >
                <PersonIcon />
              </Avatar>
            </ListItemAvatar>
            <ListItemText
              classes={{ secondary: classes.timeText }}
              primary={data.current.name}
              secondary={`${data.current.timeStart}   to   ${data.current.timeEnd}`}
            />
          </ListItem>
        </List>
      </Grid>

      <Grid item xs={12}>
        <List className={classes.root}>
          {orderBy(parentData[day], ["timeStart"], ["asc"]).map(
            (datum, idx) => {
              let timeStart = moment(String(datum.timeStart), "HH:mm").format(
                "hh:mm A"
              );
              let timeEnd = moment(String(datum.timeEnd), "HH:mm").format(
                "hh:mm A"
              );

              return (
                <ListItem key={`${datum.timeStart}_${idx}`}>
                  <ListItemText primary="Upcoming Schedule" />
                  <ListItemAvatar>
                    <Avatar
                      classes={{
                        root:
                          datum.value === "Occupied"
                            ? classes.occupiedRoot
                            : classes.unoccupiedRoot
                      }}
                    >
                      {datum.value === "Occupied" ? (
                        <PersonIcon />
                      ) : (
                        <PersonOutlineIcon />
                      )}
                    </Avatar>
                  </ListItemAvatar>
                  <ListItemText
                    classes={{ secondary: classes.timeText }}
                    primary={datum.value}
                    secondary={`${timeStart}   to   ${timeEnd}`}
                  />
                </ListItem>
              );
            }
          )}
        </List>
      </Grid>
    </Grid>
  );
};

const styles = theme => ({
  root: {
    minWidth: "unset",
    marginLeft: "1rem"
  },
  container: {
    margin: "2rem 0",
    justifyContent: "space-evenly"
  },
  icon: {
    justifyContent: "flex-end"
  },
  displayContainer: {
    display: "flex",
    justifyContent: "space-evenly",
    padding: 0,
    [theme.breakpoints.down("xs")]: {
      padding: "0 1rem"
    }
  },
  display: {
    width: "150px",
    display: "flex",
    flexDirection: "column"
  },
  currentRoot: {
    background: cyan[100]
  },
  occupiedRoot: {
    background: deepOrange[300]
  },
  unoccupiedRoot: {
    background: green[300]
  },
  timeText: {
    fontWeight: 800
  }
});

export default compose(
  connect(state => ({
    objectTypes: state.constants.objectTypes,
    api: state.user.api,
    role: state.user.role,
    email: state.user.profile.email
  })),
  withStyles(styles)
)(Schedule);
