import React from "react";
import { connect } from "react-redux";

import Container from "@material-ui/core/Container";

//Component
import AirFlow from "./Point/AirFlow";
import CalendarPoint from "./Point/CalendarPoint";
import HeatingCooling from "./Point/HeatingCooling";
import HVAC from "./Point/HVAC";
import LightDimmer from "./Point/LightDimmer";
import LightSwitch from "./Point/LightSwitch";
import Schedule from "./Point/Schedule";
import SecurityCamera from "./Point/SecurityCamera";
import SecurityDoor from "./Point/SecurityDoor";
import SecurityLog from "./Point/SecurityLog";
import Status from "./Point/Status";
import Temperature from "./Point/Temperature";
import { DATA_SAGA } from "../actions";

const Point = props => {
  const { dataObjectType, getData, hideData } = props;

  const updatedProps = { ...props, hideData };
  delete updatedProps.classes;

  const renderChild = () => {
    switch (dataObjectType) {
      case "hvac": {
        return <HVAC {...updatedProps} getData={getData} />;
      }

      case "airFlow": {
        return <AirFlow {...updatedProps} getData={getData} />;
      }

      case "temperature": {
        return <Temperature {...updatedProps} getData={getData} />;
      }

      case "lightDim": {
        return <LightDimmer {...updatedProps} getData={getData} />;
      }

      case "lightSwitch": {
        return <LightSwitch {...updatedProps} getData={getData} />;
      }

      case "calendar": {
        return <CalendarPoint {...updatedProps} getData={getData} />;
      }

      case "schedule": {
        return <Schedule {...updatedProps} getData={getData} />;
      }

      case "securityLog": {
        return <SecurityLog {...updatedProps} getData={getData} />;
      }

      case "securityDoor": {
        return <SecurityDoor {...updatedProps} getData={getData} />;
      }

      case "securityCamera": {
        return <SecurityCamera {...updatedProps} getData={getData} />;
      }

      case "heatingCooling": {
        return <HeatingCooling {...updatedProps} getData={getData} />;
      }

      case "status": {
        return <Status {...updatedProps} getData={getData} />;
      }

      default: {
        return <div />;
      }
    }
  };

  return (
    <Container maxWidth="md" style={{ padding: 0 }}>
      {renderChild()}
    </Container>
  );
};

export default connect(
  null,
  dispatch => ({
    getData: (api, filter, params, storeAs) =>
      dispatch({
        type: DATA_SAGA,
        payload: { api, filter, params, storeAs, type: "updating" }
      })
  })
)(Point);
